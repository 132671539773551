import { call, put, takeEvery } from 'redux-saga/effects'
import * as productActions from '../ducks/productDucks'
import * as productApi from '../../api/productApi'

interface FetchProductListSaga {
  type: string
  payload: string
}

interface FetchProductDetailSaga {
  type: string
  payload: string
}

function* fetchListProduct({ payload: queryString }: FetchProductListSaga) {
  try {
    const res: ReturnType<typeof productApi.getListProduct> = yield call(productApi.getListProduct, queryString)
    yield put(productActions.getListProductSuccess(res))
  } catch (error) {
    yield put(productActions.getListProductFail(error))
  }
}

function* fetchDetailProduct({ payload: id }: FetchProductDetailSaga) {
  try {
    const res: ReturnType<typeof productApi.getDetailProduct> = yield call(productApi.getDetailProduct, id)
    yield put(productActions.getDetailProductSuccess(res))
  } catch (error) {
    yield put(productActions.getDetailProductFail(error))
  }
}

export default function* watchProduct() {
  yield takeEvery(productActions.getListProduct.toString(), fetchListProduct)
  yield takeEvery(productActions.getDetailProduct.toString(), fetchDetailProduct)
}
