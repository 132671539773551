import ApisService from './index'
import { ICategorieAdd, ICategorieImport, ICategorieUpdate } from '../interfaces/categorieInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createCategorie(data: ICategorieAdd) {
  try {
    const bodyData = {...data, parentId: data.parentId == "" ? null : data.parentId}
    return ApisService.post('/muahang-backend/v1/nhomhang/create-nhomhang', bodyData)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateCategorie(data: ICategorieUpdate) {
  try {
    const bodyData = {...data, parentId: data.parentId == "" ? null : data.parentId}
    return ApisService.put('/muahang-backend/v1/nhomhang/' + data.id + '/update-nhomhang', bodyData)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailCategorie(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/nhomhang/' + id + '/get-chitiet-nhomhang')
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function getListCategorie(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/nhomhang/filter-nhomhang', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteCategorie(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/nhomhang/' + id + '/delete-nhomhang')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteMultiCategorie(idArr: Array<string>) {
  try {
    return ApisService.delete('/muahang-backend/v1/nhomhang/bulk-delete-nhomhang', { data: idArr })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function importCategorie(data: Array<ICategorieImport>) {
  try {
    return ApisService.post('/muahang-backend/v1/nhomhang/import-nhomhang', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}