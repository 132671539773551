import { call, put, takeEvery } from 'redux-saga/effects'
import * as transportationActions from '../ducks/transportationDucks'
import * as transportationApi from '../../api/transportationApi'

interface FetchTransportationListSaga {
  type: string
  payload: string
}

interface FetchTransportationDetailSaga {
  type: string
  payload: string
}

function* fetchListTransportation({ payload: queryString }: FetchTransportationListSaga) {
  try {
    const res: ReturnType<typeof transportationApi.getListTransportation> = yield call(transportationApi.getListTransportation, queryString)
    yield put(transportationActions.getListTransportationSuccess(res))
  } catch (error) {
    yield put(transportationActions.getListTransportationFail(error))
  }
}

function* fetchDetailTransportation({ payload: id }: FetchTransportationDetailSaga) {
  try {
    const res: ReturnType<typeof transportationApi.getDetailTransportation> = yield call(transportationApi.getDetailTransportation, id)
    yield put(transportationActions.getDetailTransportationSuccess(res))
  } catch (error) {
    yield put(transportationActions.getDetailTransportationFail(error))
  }
}


export default function* watchTransportation() {
  yield takeEvery(transportationActions.getListTransportation.toString(), fetchListTransportation)
  yield takeEvery(transportationActions.getDetailTransportation.toString(), fetchDetailTransportation)
}
