export const PAGE_SIZE = 20
export const PAGE_SIZE_1 = 50
export const PAGE_SIZE_2 = 300
export const PAGE_OPTIONS = ["20", "50", "100"]
export const PAGE_OPTIONS_1 = ["50", "100"]
export const PAGE_OPTIONS_2 = ["50", "100", "200", "300", "500"]
export const POSITION = ['topRight', 'bottomRight']

export const GENDER_OPTIONS = [
  { value: 0, label: 'Không xác định' },
  { value: 1, label: 'Nam' },
  { value: 2, label: 'Nữ' },
]

export const TYPE_OPTION =  [
  { value: 'CHINHNGACH', label: 'Chính ngạch' },
  { value: 'TIEUNGACH', label: 'Tiểu ngạch' },
]

export const UNIT_OPTION =  [
  { value: 'KG', label: 'KG' },
  { value: 'KHOI', label: 'KHOI' },
]
export const CN = "CHINHNGACH"
export const TN = "TIEUNGACH"
export const KHOI = "KHOI"
export const KG = "KG"

export const warehouseType = {
  source: "NGUON",
  des: "DICH",
}
