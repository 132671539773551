import React from 'react'
import ReactDOM from 'react-dom'
import './assets/locales/i18n'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { Spin } from 'antd'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Loading3QuartersOutlined } from '@ant-design/icons'

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)

Spin.setDefaultIndicator(<Loading3QuartersOutlined style={{ fontSize: 30 }} spin />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
