import ApisService from './index'
import { IProductAdd, IProductImport, IProductUpdate } from '../interfaces/productInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createProduct(data: IProductAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/sanpham/create-sanpham', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateProduct(data: IProductUpdate) {
  try {
    return ApisService.put('/muahang-backend/v1/sanpham/' + data.id + '/update-sanpham', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailProduct(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/sanpham/' + id + '/get-chitiet-sanpham')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getListProduct(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/sanpham/filter-sanpham', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteProduct(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/sanpham/' + id + '/delete-sanpham')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteMultiProduct(idArr: Array<string>) {
  try {
    return ApisService.delete('/muahang-backend/v1/sanpham/bulk-delete-sanpham', { data: idArr })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function importProduct(data: IProductImport) {
  try {
    return ApisService.post('/muahang-backend/v1/sanpham/import-sanpham', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}