import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface CurrencyProviderState {
  currencyProviderList?: any
  currencyProviderDetail?: any
}

const initialState: CurrencyProviderState = {
  currencyProviderList: {},
  currencyProviderDetail: {},
}

export const currencyProviderDucks = createSlice({
  name: 'currencyProvider',
  initialState,
  reducers: {
   
    getListCurrencyProvider: (state: RootState, action: PayloadAction<any>) => {
      state.currencyProviderList = { loading: true, queryStr: action.payload }
    },
    getListCurrencyProviderSuccess: (state, action: PayloadAction<any>) => {
      state.currencyProviderList = { loading: false, ...action.payload }
    },
    getListCurrencyProviderFail: (state, action: PayloadAction<any>) => {
      state.currencyProviderList = { loading: false, ...action.payload }
    },
    
    getDetailCurrencyProvider: (state: RootState, action: PayloadAction<any>) => {
      state.currencyProviderDetail = { loading: true, id: action.payload }
    },
    getDetailCurrencyProviderSuccess: (state, action: PayloadAction<any>) => {
      state.currencyProviderDetail = { loading: false, ...action.payload }
    },
    getDetailCurrencyProviderFail: (state, action: PayloadAction<any>) => {
      state.currencyProviderDetail = { loading: false, ...action.payload }
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListCurrencyProvider,
  getListCurrencyProviderSuccess,
  getListCurrencyProviderFail,
  getDetailCurrencyProvider,
  getDetailCurrencyProviderSuccess,
  getDetailCurrencyProviderFail,
} = currencyProviderDucks.actions

export default currencyProviderDucks.reducer
