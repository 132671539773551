import ApisService from './index'
import {
  IRequestShareAdd,
  IRequestShareUpdate,
} from '../interfaces/requestShareInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createRequestShare(data: IRequestShareAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/yeucautach/create-yeucautach', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateRequestShare(data: IRequestShareUpdate) {
  try {
    return ApisService.put('/muahang-backend/v1/yeucautach/' + data.id + '/update-yeucautach', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailRequestShare(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/yeucautach/' + id + '/get-chitiet-yeucautach')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getListRequestShare(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/yeucautach/filter-yeucautach', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteRequestShare(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/yeucautach/' + id + '/delete-yeucautach')
  } catch (err) {
    handleError(err)
    throw err
  }
}
