import { call, put, takeEvery } from 'redux-saga/effects'
import * as taxProviderActions from '../ducks/taxProviderDucks'
import * as taxProviderApi from '../../api/taxProviderApi'

interface FetchTaxProviderListSaga {
  type: string
  payload: string
}

interface FetchTaxProviderDetailSaga {
  type: string
  payload: string
}

function* fetchListTaxProvider({ payload: queryString }: FetchTaxProviderListSaga) {
  try {
    const res: ReturnType<typeof taxProviderApi.getListTaxProvider> = yield call(taxProviderApi.getListTaxProvider, queryString)
    yield put(taxProviderActions.getListTaxProviderSuccess(res))
  } catch (error) {
    yield put(taxProviderActions.getListTaxProviderFail(error))
  }
}

function* fetchDetailTaxProvider({ payload: id }: FetchTaxProviderDetailSaga) {
  try {
    const res: ReturnType<typeof taxProviderApi.getDetailTaxProvider> = yield call(taxProviderApi.getDetailTaxProvider, id)
    yield put(taxProviderActions.getDetailTaxProviderSuccess(res))
  } catch (error) {
    yield put(taxProviderActions.getDetailTaxProviderFail(error))
  }
}


export default function* watchTaxProvider() {
  yield takeEvery(taxProviderActions.getListTaxProvider.toString(), fetchListTaxProvider)
  yield takeEvery(taxProviderActions.getDetailTaxProvider.toString(), fetchDetailTaxProvider)
}
