import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface TransportationState {
  transportationList?: any
  transportationDetail?: any
}

const initialState: TransportationState = {
  transportationList: {},
  transportationDetail: {},
}

export const transportationDucks = createSlice({
  name: 'transportation',
  initialState,
  reducers: {
   
    getListTransportation: (state: RootState, action: PayloadAction<any>) => {
      state.transportationList = { loading: true, queryStr: action.payload }
    },
    getListTransportationSuccess: (state, action: PayloadAction<any>) => {
      state.transportationList = { loading: false, ...action.payload }
    },
    getListTransportationFail: (state, action: PayloadAction<any>) => {
      state.transportationList = { loading: false, ...action.payload }
    },
    getDetailTransportation: (state: RootState, action: PayloadAction<any>) => {
      state.transportationDetail = { loading: true, orderId: action.payload }
    },
    getDetailTransportationSuccess: (state, action: PayloadAction<any>) => {
      state.transportationDetail = { loading: false, ...action.payload }
    },
    getDetailTransportationFail: (state, action: PayloadAction<any>) => {
      state.transportationDetail = { loading: false, ...action.payload }
    },

    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListTransportation,
  getListTransportationSuccess,
  getListTransportationFail,
  getDetailTransportation,
  getDetailTransportationSuccess,
  getDetailTransportationFail,
} = transportationDucks.actions

export default transportationDucks.reducer
