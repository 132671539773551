import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface ProductState {
  productList?: any
  productDetail?: any
}

const initialState: ProductState = {
  productList: {},
  productDetail: {},
}

export const productDucks = createSlice({
  name: 'product',
  initialState,
  reducers: {
   
    getListProduct: (state: RootState, action: PayloadAction<any>) => {
      state.productList = { loading: true, queryStr: action.payload }
    },
    getListProductSuccess: (state, action: PayloadAction<any>) => {
      state.productList = { loading: false, ...action.payload }
    },
    getListProductFail: (state, action: PayloadAction<any>) => {
      state.productList = { loading: false, ...action.payload }
    },
    getDetailProduct: (state: RootState, action: PayloadAction<any>) => {
      state.productDetail = { loading: true, id: action.payload }
    },
    getDetailProductSuccess: (state, action: PayloadAction<any>) => {
      state.productDetail = { loading: false, ...action.payload }
    },
    getDetailProductFail: (state, action: PayloadAction<any>) => {
      state.productDetail = { loading: false, ...action.payload }
    },

    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListProduct,
  getListProductSuccess,
  getListProductFail,
  getDetailProduct,
  getDetailProductSuccess,
  getDetailProductFail,
} = productDucks.actions

export default productDucks.reducer
