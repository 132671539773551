import ApisService from './index'
import { ICurrencyProviderAdd, ICurrencyProviderUpdate } from '../interfaces/currencyProviderInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createCurrencyProvider(data: ICurrencyProviderAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/nhacungcap-tiente/create-nhacungcap-tiente', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateCurrencyProvider(data: ICurrencyProviderUpdate) {
  try {
    return ApisService.put('/muahang-backend/v1/nhacungcap-tiente/' + data.id + '/update-nhacungcap-tiente', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailCurrencyProvider(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/nhacungcap-tiente/' + id + '/get-chitiet-nhacungcap-tiente')
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function getListCurrencyProvider(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/nhacungcap-tiente/filter-nhacungcap-tiente', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteCurrencyProvider(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/nhacungcap-tiente/' + id + '/delete-nhacungcap-tiente')
  } catch (err) {
    handleError(err)
    throw err
  }
}
