import { notification, message } from 'antd'
import * as queryString from 'query-string'

export function showNoti(type: 'success' | 'error' | 'warning' | 'info', text: string) {
  notification[type]({
    message: 'Thông báo',
    description: text,
  })
}

export function showMessage(type: 'info' | 'success' | 'error' | 'warning' | 'loading', text: string) {
  message[type](text, 4.5)
}

export function isEmailValid(email: string) {
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function isPhoneValid(phone: string) {
  try {
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
    const phoneNumber = phoneUtil.parse(phone, 'VN')
    return phoneUtil.isValidNumber(phoneNumber, 'VN')
  } catch (e) {
    return false
  }
}

export function handleError(err: any) {
  if (err.message) showMessage('error', err.message + ' (' + err.code + ')')
  else {
    console.error(err)
  }
}

export function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export function showPrice(price: number | undefined) {4
  if(price == undefined) return ""
  return Number(price).toLocaleString('en-US', { maximumFractionDigits: 6, useGrouping: true })
}

export function showPriceBK(price: number, currency?: string) {
  if (price === undefined || price === null) {
    return ''
  }
  const currencyToCode = (_curr?: string) => {
    if (_curr === 'CNY') return '¥'
    if (_curr === 'USD') return '$'
    if (_curr === null || _curr === undefined || _curr === '') return ''
    return 'đ'
  }
  return price.toLocaleString('vi', { maximumFractionDigits: 2 }) + currencyToCode(currency)
}

export function convertGender(gender: number) {
  if (gender === 1) return 'Nam'
  if (gender === 2) return 'Nữ'
  return ''
}

export const renderFormula = (formula: string) => {
  switch (formula) {
    case 'compare_kg_and_m3':
      return 'So sánh giá'
    case 'per_kg':
      return 'Theo cân nặng (kg)'
    case 'per_m3':
      return 'Theo thể tích (m3)'
    default:
      return ''
  }
}

export const renderExportImportService = (exportImportService: string) => {
  switch (exportImportService) {
    case 'container_consolidation':
      return 'Gom container'
    case 'full_container':
      return 'Full container'
    default:
      return 'Chưa cấu hình'
  }
}

export const handleChangePaging = ({
  history,
  pathname,
  search,
  page,
  pageSize,
}: {
  history: any
  pathname: string
  search: string
  page: number
  pageSize?: number
}) => {
  const parsed: any = queryString.parse(search)
  parsed.page = page
  if (pageSize) parsed.page_size = pageSize
  const stringified = queryString.stringify(parsed, { skipEmptyString: true })
  history.push(pathname + '?' + stringified)
}


export function formatter(value: number | undefined) {
  return Number(value).toLocaleString('en-US', { maximumFractionDigits: 6, useGrouping: true })
}



export function formatterSoKhoi(value: number | undefined) {
  return Number(value).toLocaleString('en-US', { maximumFractionDigits: 6, useGrouping: true })
}

export const formatterNumber = (val) => {
  if (!val) return 0;
  return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ",");
}
