import ApisService from './index'
import {
  IRequestPaymentAdd,
  IRequestPaymentUpdate,
} from '../interfaces/requestPaymentInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createRequestPayment(data: IRequestPaymentAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/donmua-yeucauthanhtoan/create-donmuayeucauthanhtoan', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateRequestPayment(data: IRequestPaymentUpdate) {
  try {
    return ApisService.put('/muahang-backend/v1/donmua-yeucauthanhtoan/' + data.id + '/update-donmuayeucauthanhtoan', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailRequestPayment(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/donmua-yeucauthanhtoan/' + id + '/get-chitiet-donmuayeucauthanhtoan')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getListRequestPayment(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/donmua-yeucauthanhtoan/filter-donmuayeucauthanhtoan', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteRequestPayment(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/donmua-yeucauthanhtoan/' + id + '/delete-donmuayeucauthanhtoan')
  } catch (err) {
    handleError(err)
    throw err
  }
}
