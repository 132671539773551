import ApisService from './index'
import { ISupplierAdd, ISupplierImport, ISupplierUpdate } from '../interfaces/supplierInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createSupplier(data: ISupplierAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/nhacungcap/create-nhacungcap', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateSupplier(data: ISupplierUpdate) {
  try {
    return ApisService.put('/muahang-backend/v1/nhacungcap/' + data.id + '/update-nhacungcap', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailSupplier(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/nhacungcap/' + id + '/get-chitiet-nhacungcap')
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function getListSupplier(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/nhacungcap/filter-nhacungcap', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteSupplier(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/nhacungcap/' + id + '/delete-nhacungcap')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteMultiSupplier(idArr: Array<string>) {
  try {
    return ApisService.delete('/muahang-backend/v1/nhacungcap/bulk-delete-nhacungcap', { data: idArr })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function importSupplier(data: Array<ISupplierImport>) {
  try {
    return ApisService.post('/muahang-backend/v1/nhacungcap/import-nhacungcap', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}