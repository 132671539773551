import i18next from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationEn from './en.json'
import translationVi from './vi.json'
import translationZh from './zh.json'

i18next
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      lng: 'vi',
      resources: {
        en: {
          translation: translationEn,
        },
        vi: {
          translation: translationVi,
        },
        zh: {
          translation: translationZh,
        },
      },
      detection: {
        // order and from where user language should be detected
        order: ['querystring', 'localStorage', 'navigator'],

        // keys or params to lookup language from
        lookupQuerystring: 'lang',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,

        // cache user language on
        caches: ['localStorage', 'cookie'],
      },
      // defaultNS: 'translation',
      fallbackNS: 'translation',
      fallbackLng: ['vi', 'en', 'zh'],

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      react: {
        useSuspense: false,
      },
    },
    (err: any, t: any) => {
      if (err) return console.log('something went wrong loading i18n', err)
      t('key') // -> same as i18next.t
    }
  )

export default i18next
