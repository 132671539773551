import ApisService from './index'
import { IProductGroupAdd, IProductGroupUpdate } from '../interfaces/productGroupInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createProductGroup(data: IProductGroupAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/nhomcongbosanpham/create-nhomcongbosanpham', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateProductGroup(data: IProductGroupUpdate) {
  try {
    return ApisService.put('/muahang-backend/v1/nhomcongbosanpham/' + data.id + '/update-nhomcongbosanpham', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailProductGroup(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/nhomcongbosanpham/' + id + '/get-chitiet-nhomcongbosanpham')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getListProductGroup(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/nhomcongbosanpham/filter-nhomcongbosanpham', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteProductGroup(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/nhomcongbosanpham/' + id + '/delete-nhomcongbosanpham')
  } catch (err) {
    handleError(err)
    throw err
  }
}
