import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface TaxProviderState {
  taxProviderList?: any
  taxProviderDetail?: any
}

const initialState: TaxProviderState = {
  taxProviderList: {},
  taxProviderDetail: {},
}

export const taxProviderDucks = createSlice({
  name: 'taxProvider',
  initialState,
  reducers: {
   
    getListTaxProvider: (state: RootState, action: PayloadAction<any>) => {
      state.taxProviderList = { loading: true, queryStr: action.payload }
    },
    getListTaxProviderSuccess: (state, action: PayloadAction<any>) => {
      state.taxProviderList = { loading: false, ...action.payload }
    },
    getListTaxProviderFail: (state, action: PayloadAction<any>) => {
      state.taxProviderList = { loading: false, ...action.payload }
    },
    
    getDetailTaxProvider: (state: RootState, action: PayloadAction<any>) => {
      state.taxProviderDetail = { loading: true, id: action.payload }
    },
    getDetailTaxProviderSuccess: (state, action: PayloadAction<any>) => {
      state.taxProviderDetail = { loading: false, ...action.payload }
    },
    getDetailTaxProviderFail: (state, action: PayloadAction<any>) => {
      state.taxProviderDetail = { loading: false, ...action.payload }
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListTaxProvider,
  getListTaxProviderSuccess,
  getListTaxProviderFail,
  getDetailTaxProvider,
  getDetailTaxProviderSuccess,
  getDetailTaxProviderFail,
} = taxProviderDucks.actions

export default taxProviderDucks.reducer
