import { call, put, takeEvery } from 'redux-saga/effects'
import * as rateActions from '../ducks/rateDucks'
import * as rateApi from '../../api/rateApi'
// import { formList } from '../../components/common/dataFake'

interface FetchRateListSaga {
  type: string
  payload: string
}

interface FetchRateDetailSaga {
  type: string
  payload: string
}

function* fetchListRate({ payload: queryString }: FetchRateListSaga) {
  try {
    const res: ReturnType<typeof rateApi.getListRate> = yield call(rateApi.getListRate, queryString)
    yield put(rateActions.getListRateSuccess(res))
  } catch (error) {
    yield put(rateActions.getListRateFail(error))
  }
}


function* fetchListForm({ payload: queryString }: FetchRateListSaga) {
  console.log(queryString)
  try {
    const res: ReturnType<typeof rateApi.getListForm> = yield call(rateApi.getListForm, queryString)
    yield put(rateActions.getListFormSuccess(res))
    // yield put(rateActions.getListFormSuccess(formList))
  } catch (error) {
    yield put(rateActions.getListFormFail(error))
  }
}

function* fetchDetailRate({ payload: id }: FetchRateDetailSaga) {
  try {
    const res: ReturnType<typeof rateApi.getDetailRate> = yield call(rateApi.getDetailRate, id)
    yield put(rateActions.getDetailRateSuccess(res))
  } catch (error) {
    yield put(rateActions.getDetailRateFail(error))
  }
}

export default function* watchRate() {
  yield takeEvery(rateActions.getListRate.toString(), fetchListRate)
  yield takeEvery(rateActions.getListForm.toString(), fetchListForm)
  yield takeEvery(rateActions.getDetailRate.toString(), fetchDetailRate)
}
