import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface RequestShareState {
  requestShareList?: any
  requestShareDetail?: any
  requestShare?: {
    id?: string
    yeuCauMua: any
    yeuCauMuaId: string
    noiDung: string
    isOpenModal: boolean
  }

}

const initialState: RequestShareState = {
  requestShareList: {},
  requestShareDetail: {},
  requestShare: {
    id: "",
    yeuCauMua: {},
    yeuCauMuaId: "",
    noiDung: "",
    isOpenModal: false
  }


}

export const requestShareDucks = createSlice({
  name: 'requestShare',
  initialState,
  reducers: {

    getListRequestShare: (state: RootState, action: PayloadAction<any>) => {
      state.requestShareList = { loading: true, queryStr: action.payload }
    },
    getListRequestShareSuccess: (state, action: PayloadAction<any>) => {
      state.requestShareList = { loading: false, ...action.payload }
    },
    getListRequestShareFail: (state, action: PayloadAction<any>) => {
      state.requestShareList = { loading: false, ...action.payload }
    },

    getDetailRequestShare: (state: RootState, action: PayloadAction<any>) => {
      state.requestShareDetail = { loading: true, id: action.payload }
    },
    getDetailRequestShareSuccess: (state, action: PayloadAction<any>) => {
      state.requestShareDetail = { loading: false, ...action.payload }
    },
    getDetailRequestShareFail: (state, action: PayloadAction<any>) => {
      state.requestShareDetail = { loading: false, ...action.payload }
    },

    setRequestShare: (state, action: PayloadAction<any>) => {
      state.requestShare = action.payload
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListRequestShare,
  getListRequestShareSuccess,
  getListRequestShareFail,
  getDetailRequestShare,
  getDetailRequestShareSuccess,
  getDetailRequestShareFail,
  setRequestShare,
  
} = requestShareDucks.actions

export default requestShareDucks.reducer
