import ApisService from './index'
import {
  IOrderAdd, IOrderUpdate,
  IHistoryOrderDetailFilter,
  IOrderPayment, IBillOfLadingCodeImport,
  IOrderPurchases, IOrderPurchasesReality
} from '../interfaces/orderInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

//  Tạo báo giá
export function createOrder(data: IOrderAdd) {
  // const bodyData = {...data}
  const donMuaYeuCauMuas = data.donMuaYeuCauMuas.map((item1) => {
    const donMuaYeuCauMuaHinhThucVanChuyens = item1.donMuaYeuCauMuaHinhThucVanChuyens.map(item2 => {
      return { ...item2, hinhThucVanChuyenId: item2?.hinhThucVanChuyen?.id }
    })
    return {
      ...item1,
      donMuaYeuCauMuaHinhThucVanChuyens: donMuaYeuCauMuaHinhThucVanChuyens
    }
  })
  try {
    return ApisService.post('/muahang-backend/v1/donmua/create-donmua', { ...data, donMuaYeuCauMuas: donMuaYeuCauMuas })
  } catch (err) {
    handleError(err)
    throw err
  }
}
// tính giá vốn
export function getCapitalPriceOrder(data: IOrderAdd) {
  // const bodyData = {...data}
  const donMuaYeuCauMuas = data.donMuaYeuCauMuas.map((item1) => {
    const donMuaYeuCauMuaHinhThucVanChuyens = item1.donMuaYeuCauMuaHinhThucVanChuyens.map(item2 => {
      return {
        hinhThucVanChuyenId: item2?.hinhThucVanChuyen?.id,
        tienVanChuyenNoiDia: item2?.tienVanChuyenNoiDia || 0,
        tyGiaVanChuyenNoiDia: item2?.tyGiaVanChuyenNoiDia || 0,
        isShow: item2?.isShow,
      }
    })
    return {
      ...item1,
      donMuaYeuCauMuaHinhThucVanChuyens: donMuaYeuCauMuaHinhThucVanChuyens
    }
  })
  try {
    return ApisService.post('/muahang-backend/v1/donmua/' + data.id + '/tinhgiavon-donmua', { ...data, donMuaYeuCauMuas: donMuaYeuCauMuas })
  } catch (err) {
    handleError(err)
    throw err
  }
}

// tính giá vốn
export function getCapitalPriceOrderContainer(data: IOrderAdd) {
  // const bodyData = {...data}
  const donMuaYeuCauMuas = data.donMuaYeuCauMuas.map((item1) => {
    const donMuaYeuCauMuaHinhThucVanChuyens = item1.donMuaYeuCauMuaHinhThucVanChuyens.map(item2 => {
      return {
        hinhThucVanChuyenId: item2?.hinhThucVanChuyen?.id,
        tienVanChuyenNoiDia: item2?.tienVanChuyenNoiDia || 0,
        tyGiaVanChuyenNoiDia: item2?.tyGiaVanChuyenNoiDia || 0,
        isShow: item2?.isShow,
      }
    })
    return {
      ...item1,
      donMuaYeuCauMuaHinhThucVanChuyens: donMuaYeuCauMuaHinhThucVanChuyens
    }
  })
  try {
    return ApisService.post('/muahang-backend/v1/donmua/' + data.id + '/tinhgiavon-donmua', { ...data, donMuaYeuCauMuas: donMuaYeuCauMuas })
  } catch (err) {
    handleError(err)
    throw err
  }
}

// cập nhật báo giá
export function updateOrder(data: IOrderAdd) {



  const donMuaYeuCauMuas = data.donMuaYeuCauMuas.map((item1) => {
    const donMuaYeuCauMuaHinhThucVanChuyens = item1.donMuaYeuCauMuaHinhThucVanChuyens.map(item2 => {
      return {
        hinhThucVanChuyenId: item2?.hinhThucVanChuyen?.id,
        tienVanChuyenNoiDia: item2?.tienVanChuyenNoiDia || 0,
        tyGiaVanChuyenNoiDia: item2?.tyGiaVanChuyenNoiDia || 0,
        isShow: item2?.isShow,
      }
    })
    return {
      ...item1,
      donMuaYeuCauMuaHinhThucVanChuyens: donMuaYeuCauMuaHinhThucVanChuyens
    }
  })
  try {
    return ApisService.put('/muahang-backend/v1/donmua/' + data.id + '/update-donmua', { ...data, donMuaYeuCauMuas: donMuaYeuCauMuas })
  } catch (err) {
    handleError(err)
    throw err
  }
}

// gét chi tiết báo giá, đơn mua
export function getDetailOrder(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/donmua/' + id + '/get-chitiet-donmua')
  } catch (err) {
    handleError(err)
    throw err
  }
}

// Lấy danh sách báo giá, đơn mua
export function getListOrder(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/donmua/filter-donmua', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteOrder(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/donmua/' + id + '/delete-donmua')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteMultiOrder(idArr: Array<string>) {
  try {
    return ApisService.delete('/muahang-backend/v1/donmua/bulk-delete-donmua', { data: idArr })
  } catch (err) {
    handleError(err)
    throw err
  }
}

// Lấy danh sách yêu cầu mua cần báo giá
export function getListRequest(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/donmua/filter-yeucaumua', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

// chốt báo giá
export function updateOrderConfirm(id: string) {
  try {
    return ApisService.put('/muahang-backend/v1/donmua/' + id + '/chotbaogia')
  } catch (err) {
    handleError(err)
    throw err
  }
}


// cập hật tình trạng đơn hàng
export function updateOrderAttach(data: IOrderUpdate) {
  const bodyData: Array<any> = data.donMuaYeuCauMuas.map((item: IOrderPurchases) => {
    return {
      ...item.donMuaYeuCauMuaThongTinThem,
      donMuaYeuCauMuaId: item.id
    }
  })

  try {
    return ApisService.put('/muahang-backend/v1/donmua/' + data.id + '/update-donmua-thongtinthem', { thongTinThems: bodyData })
  } catch (err) {
    handleError(err)
    throw err
  }
}

// cập nhập hàng về thực tế
export function updateOrderReality(data: IOrderPurchasesReality) {
  try {
    return ApisService.put('/muahang-backend/v1/donmua/update-hangve-thucte', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

// báo cáo
// tính giá vốn
export function getListReportCapitalPrice(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/donmua/baocao-giavon', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}
// báo cáo công nợ
export function getListReportDeptList(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/donmua/baocao-tonghop-congnoncc', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

// báo cáo công nợ chi tiết
export function getListReportDeptDetail(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/donmua/baocao-chitiet-congnoncc', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}


// báo cáo tình trạng đặt hàng
export function getListReportOrderStatus(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/donmua/baocao-tinhtrang-dathang', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getHistoryOrderLatest(payload: IHistoryOrderDetailFilter) {
  try {
    return ApisService.get('/muahang-backend/v1/donmua/get-donmua-gan-nhat', {
      params: payload
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getListHistoryOrder(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/donmua/baocao-tonghop', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}


// Thanh toán nhiều lần
export function createPaymentOrder(donMuaId: string, data: IOrderPayment) {
  const bodyData = { ...data, ngayThanhToan: parseInt(data.ngayThanhToan) }
  try {
    return ApisService.put('/muahang-backend/v1/donmua/' + donMuaId + '/thanhtoandonmua', bodyData)
  } catch (err) {
    handleError(err)
    throw err
  }
}


// thanh toán
export function updatePaymentOrder(data: IOrderPayment) {
  try {
    return ApisService.put('/muahang-backend/v1/donmua/' + data.id + '/thanhtoandonmua', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}



// import mã vận đơn

export function importbillOfLadingCode(data: Array<IBillOfLadingCodeImport>) {
  try {
    return ApisService.post('/muahang-backend/v1/donmua/import-tracuu', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}


// Lấy danh sách báo giá, đơn mua
export function getListBillOfLadingCode(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/donmua/filter-tracuu', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

