import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface TransProviderState {
  transProviderList?: any
  transProviderDetail?: any
}

const initialState: TransProviderState = {
  transProviderList: {},
  transProviderDetail: {},
}

export const transProviderDucks = createSlice({
  name: 'transProvider',
  initialState,
  reducers: {
   
    getListTransProvider: (state: RootState, action: PayloadAction<any>) => {
      state.transProviderList = { loading: true, queryStr: action.payload }
    },
    getListTransProviderSuccess: (state, action: PayloadAction<any>) => {
      state.transProviderList = { loading: false, ...action.payload }
    },
    getListTransProviderFail: (state, action: PayloadAction<any>) => {
      state.transProviderList = { loading: false, ...action.payload }
    },
    
    getDetailTransProvider: (state: RootState, action: PayloadAction<any>) => {
      state.transProviderDetail = { loading: true, id: action.payload }
    },
    getDetailTransProviderSuccess: (state, action: PayloadAction<any>) => {
      state.transProviderDetail = { loading: false, ...action.payload }
    },
    getDetailTransProviderFail: (state, action: PayloadAction<any>) => {
      state.transProviderDetail = { loading: false, ...action.payload }
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListTransProvider,
  getListTransProviderSuccess,
  getListTransProviderFail,
  getDetailTransProvider,
  getDetailTransProviderSuccess,
  getDetailTransProviderFail,
} = transProviderDucks.actions

export default transProviderDucks.reducer
