import { call, put, takeEvery } from 'redux-saga/effects'
import * as notificationActions from '../ducks/notificationDucks'
import * as notificationApi from '../../api/notificationApi'

interface FetchRateListSaga {
  type: string
  payload: string
}


function* fetchListNotification({ payload: queryString }: FetchRateListSaga) {
  try {
    const res: ReturnType<typeof notificationApi.getListNotification> = yield call(notificationApi.getListNotification, queryString)
    yield put(notificationActions.getListNotificationSuccess(res))
  } catch (error) {
    yield put(notificationActions.getListNotificationFail(error))
  }
}


function* fetchListAllNotification({ payload: queryString }: FetchRateListSaga) {
  try {
    const res: ReturnType<typeof notificationApi.getListAllNotification> = yield call(notificationApi.getListAllNotification, queryString)
    yield put(notificationActions.getListAllNotificationSuccess(res))
  } catch (error) {
    yield put(notificationActions.getListAllNotificationFail(error))
  }
}

export default function* watchNotification() {
  yield takeEvery(notificationActions.getListNotification.toString(), fetchListNotification)
  yield takeEvery(notificationActions.getListAllNotification.toString(), fetchListAllNotification)
}
