import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
// SUPPER_ADMIN: 'super_admin',
//   ADMIN: 'admin',
//   ORDER: 'mua_hang',
//   SALE: 'kinh_doanh',
//   ACCOUNTANT: 'ke_toan',

export interface InitState {
  isLoading: boolean
  userList: any
  userDetail: any
  userListRole: any
  projectList: any

}

const initialState: InitState = {
  isLoading: false,
  userList: {},
  userDetail: {},
  userListRole: {},
  projectList: {}
}

export const userDucks: any = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state: RootState) => {
      state.responseLogin = { loading: true, error: false }
    },
    loginSuccess: (state: RootState, { payload: responseLogin }) => {
      state.responseLogin = {
        loading: false,
        error: false,
        response: responseLogin,
      }
    },
    loginFail: (state: RootState, { payload: error }) => {
      state.responseLogin = {
        loading: false,
        error: true,
        response: error,
      }
    },
  
    getListUser: (state: RootState) => {
      state.userList = { loading: true }
    },
    getListUserSuccess: (state: RootState, action: PayloadAction<any>) => {
      state.userList = { loading: false, ...action.payload }
    },
    getListUserFail: (state: RootState, action: PayloadAction<any>) => {
      state.userList = { loading: false, ...action.payload }
    },

    getDetailUser: (state: RootState, action: PayloadAction<any>) => {
      state.userDetail = { loading: true, id: action.payload }
    },
    getDetailUserSuccess: (state, action: PayloadAction<any>) => {
      state.userDetail = { loading: false, ...action.payload }
    },
    getDetailUserFail: (state, action: PayloadAction<any>) => {
      state.userDetail = { loading: false, ...action.payload }
    },

    getListRole: (state: RootState) => {
      state.roleList = { loading: true }
    },
    getListRoleSuccess: (state: RootState, action: PayloadAction<any>) => {
      state.roleList = { loading: false, ...action.payload }
    },
    getListRoleFail: (state: RootState, action: PayloadAction<any>) => {
      state.roleList = { loading: false, ...action.payload }
    },


    getListUserByRole: (state: RootState) => {
      state.userListRole = { loading: true }
    },
    getListUserByRoleSuccess: (state: RootState, action: PayloadAction<any>) => {
      state.userListRole = { loading: false, ...action.payload }
    },
    getListUserByRoleFail: (state: RootState, action: PayloadAction<any>) => {
      state.userListRole = { loading: false, ...action.payload }
    },


    getListProject: (state: RootState) => {
      state.projectList = { loading: true }
    },
    getListProjectSuccess: (state: RootState, action: PayloadAction<any>) => {
      state.projectList = { loading: false, ...action.payload }
    },
    getListProjectFail: (state: RootState, action: PayloadAction<any>) => {
      state.projectList = { loading: false, ...action.payload }
    },


  },
})

// Action creators are generated for each case reducer function
export const {
  login,
  loginSuccess,
  loginFail,

  getListUser,
  getListUserSuccess,
  getListUserFail,

  getDetailUser,
  getDetailUserSuccess,
  getDetailUserFail,

  getListRole,
  getListRoleSuccess,
  getListRoleFail,

  getListUserByRole,
  getListUserByRoleSuccess,
  getListUserByRoleFail,

  getListProject,
  getListProjectSuccess,
  getListProjectFail,
} =
  userDucks.actions

export default userDucks.reducer
