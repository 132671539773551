import React, { ReactElement, useEffect, useState } from 'react'
import './Layout.scss'
import { getCurrentConfig } from '../../api/commonApi'
import { setRateCnyToVnd } from '../../redux/ducks/commonDucks'
import { AppDispatch, RootState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Menu, Dropdown, Button, Row } from 'antd';
import { Link, useLocation } from 'react-router-dom'
import routerConstants from '../../constants/routerConstants'
const { Header, Sider, Content } = Layout;
import { getUserRole, getUserInfo, logOut, getProjectInfo } from '../../utils/userUtils'
import { role } from '../../constants/roleConstants'
import * as queryString from 'query-string'
import { getListAllNotification } from '../../redux/ducks/notificationDucks'
import { NotificationOutlined } from '@ant-design/icons'
import * as notificationApi from '../../api/notificationApi'
import moment from 'moment'
import * as userApi from '../../api/userApi'
import { saveAccessToken, saveUserRole, saveProjectInfo, saveUserInfo } from '../../utils/userUtils'
import { CaretRightOutlined } from '@ant-design/icons'
export enum notifyType {
  CANXACNHANBAOGIA = 'CANXACNHANBAOGIA',
  COTHAYDOIBAOGIA = 'COTHAYDOIBAOGIA',
  COYEUCAUMUA = 'COYEUCAUMUA',
  COYEUCAUTACH = 'COYEUCAUTACH',
  COYEUCAUTHANHTOAN = 'COYEUCAUTHANHTOAN',
}

const { innerHeight: height } = window;
const styleActive = {
  background: "#319795",
  borderRadius: 20,
  color: 'white',
  margin: 2

}
const styleInActive = {
  borderRadius: 20,
  background: "#d5e8ed",
  margin: 2

}
interface Props {
  children: ReactElement
}
const BasicLayout: React.FC<Props> = ({ children }) => {
  const dispatch: AppDispatch = useDispatch()
  // const history = useHistory()
  const { pathname } = useLocation()
  const userInfo = getUserInfo()
  const projectInfo = getProjectInfo()
  const user_role = getUserRole()
  const notificationAllList = useSelector((state: RootState) => state.notification.notificationAllList || {})
  const [count, setCount] = useState(0)
  const [countNoty, setCountNoty] = useState({
    countXnbg: 0,
    countTdbg: 0,
    countYcm: 0,
    countYct: 0,
    countYctt: 0,
  })
  const [projectList, setProjectList] = useState<any[]>([])
  const [notification, setNotification] = useState<any[]>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [refType, setRefType] = useState<string>("ALL")

  const filter = {
    from_date: moment().subtract(60, "days").format('YYYYMMDD'),
    to_date: moment().format('YYYYMMDD'),
  }

  useEffect(() => {
    async function fetchExChangeRate() {
      const res = await getCurrentConfig()
      const rate = res.data.rateCnyVnd
      dispatch(setRateCnyToVnd(Number(rate.toFixed(2))))
    }
    fetchExChangeRate()
  }, [])

  useEffect(() => {
    getNotification()
  }, [])
  useEffect(() => {
    const myInterval = window.setInterval(() => {
      getNotification()
    }, 3000 * 60);
    return () => clearInterval(myInterval);
  }, [])

  const getNotification = () => {
    const stringified = queryString.stringify({ ...filter, page: 1, page_size: 1000 }, { skipEmptyString: true })
    dispatch(getListAllNotification(stringified))
  }

  useEffect(() => {
    getProjectList()
  }, [])
  const getProjectList = async () => {
    const res = await userApi.getListProject()
    // console.log('---res---', res)
    setProjectList(res.data)
  }

  useEffect(() => {
    setCount(notificationAllList?.additionalData?.unReadCount || 0)
    let notify: any[] = []
    let notifyWatched: any[] = []
    let countXnbg = 0
    let countTdbg = 0
    let countYcm = 0
    let countYct = 0
    let countYctt = 0
    if (Array.isArray(notificationAllList.data) && notificationAllList.data.length > 0) {
      notificationAllList.data.map(item => {
        countXnbg += (!item.watched && item.refType == notifyType.CANXACNHANBAOGIA) ? 1 : 0
        countTdbg += (!item.watched && item.refType == notifyType.COTHAYDOIBAOGIA) ? 1 : 0
        countYcm += (!item.watched && item.refType == notifyType.COYEUCAUMUA) ? 1 : 0
        countYct += (!item.watched && item.refType == notifyType.COYEUCAUTACH) ? 1 : 0
        countYctt += (!item.watched && item.refType == notifyType.COYEUCAUTHANHTOAN) ? 1 : 0
        if ((item.refType == refType || refType == "ALL")) {
          if (item.watched) {
            notifyWatched.push(item)
          } else {
            notify.push(item)
          }
        }
      })
    }
    setCountNoty({
      countXnbg: countXnbg,
      countTdbg: countTdbg,
      countYcm: countYcm,
      countYct: countYct,
      countYctt: countYctt,
    })
    setNotification([...notify, ...notifyWatched])
  }, [JSON.stringify(notificationAllList), refType])

  const updateNofification = async (item: any) => {
    if (!item.watched) {
      await notificationApi.updateNotification(item.id)
      getNotification()
    }

    if (item.refType == notifyType.CANXACNHANBAOGIA || item.refType == notifyType.COTHAYDOIBAOGIA) {
      window.open(routerConstants.pricingConfirm.detail.replace(":id", item.refId))
    } else if (item.refType == notifyType.COYEUCAUMUA) {
      window.open(routerConstants.pricing.add);
    } else if (item.refType == notifyType.COYEUCAUTHANHTOAN) {
      window.open(routerConstants.requestPayment.list)
    } else {
      window.open(routerConstants.pricing.add)
    }
    setVisible(false)
  }

  const changeProject = async (item: any) => {
    const res = await userApi.changeProject(item.id)
    saveAccessToken(res.data.access_token)
    saveUserRole(res.data.role)
    saveProjectInfo(JSON.stringify(res.data.duAn))
    const resUser = await userApi.getUserInfo()
    saveUserInfo(JSON.stringify(resUser.data))
    window.location.href = "/"
  }

  return (
    <Layout style={{ borderTop: '2px solid grey' }}>
      <Sider className='sidebar-custom' trigger={null} collapsible collapsed={false} >
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu style={{ marginTop: 0, left: 200, top: -40, width: 200, border: "1px solid grey", backgroundColor: "#1E7270", borderRadius: 5, }}>
              {Array.isArray(projectList) && projectList.length > 0 &&
                projectList.map((item, index) => {
                  return (<Menu.Item key={index} style={{
                    backgroundColor: item.ma == projectInfo?.ma ? "#319795" : "#1E7270",
                    color: 'white', fontSize: 18, margin: 5, borderRadius: 5,
                    paddingTop: 7, paddingBottom: 7
                  }} onClick={() => changeProject(item)} >
                    <div>{item.ten}</div>
                  </Menu.Item>
                  )
                })
              }
            </Menu>
          }
        >
          <div style={{
            // height: '100px',
            margin: '2px',
            color: 'white',
            // textAlign: 'center',
            alignContent: 'center',
            borderBottom: '1px solid grey',
            paddingLeft: 15,
            paddingRight: 15
          }}>
            <div style={{ fontSize: '24px', color: 'white', flexDirection: 'row', display: 'flex' }}>
              <div style={{ flex: 1 }}>
                {projectInfo?.ten}
              </div>
              <div style={{}}>
                <CaretRightOutlined style={{ textAlign: 'right' }} />
              </div>
            </div>
            {/* <DownOutlined /> */}
          </div>
        </Dropdown>

        <Dropdown
          trigger={['click']}
          visible={visible}
          overlay={
            <Menu style={{ left: 200, top: 0, width: 450, maxHeight: height - 100, backgroundColor: 'white', border: "1px solid grey", position: 'absolute' }}>
              <Menu.Item key={"notification-all"} style={{ borderBottomWidth: 2, backgroundColor: '#EEEEEE', borderBottom: "2px solid grey", marginTop: -2 }} >
                <Button style={{ backgroundColor: "red", borderRadius: 20, color: 'white', marginRight: 10 }} size='small' onClick={() => setVisible(false)} type='text'>Đóng</Button>
                <Button style={refType == "ALL" ? styleActive : styleInActive} size='small' onClick={() => setRefType("ALL")} type='text'>Tất cả - {count}</Button>
                <Button
                  hidden={!(user_role == role.SUPPER_ADMIN || user_role == role.ADMIN || user_role == role.SALE || user_role == role.SALE_MANAGER)}
                  style={refType == notifyType.CANXACNHANBAOGIA ? styleActive : styleInActive} size='small' onClick={() => setRefType(notifyType.CANXACNHANBAOGIA)} type='text'
                >Xác nhận báo giá - {countNoty.countXnbg}</Button>
                <Button
                  hidden={!(user_role == role.SUPPER_ADMIN || user_role == role.ADMIN || user_role == role.SALE || user_role == role.SALE_MANAGER)}
                  style={refType == notifyType.COTHAYDOIBAOGIA ? styleActive : styleInActive} size='small' onClick={() => setRefType(notifyType.COTHAYDOIBAOGIA)} type='text'
                >Thay đổi báo giá - {countNoty.countTdbg}</Button>
                <Button
                  hidden={!(user_role == role.SUPPER_ADMIN || user_role == role.ADMIN || user_role == role.ORDER || user_role == role.ORDER_MANAGER)}
                  style={refType == notifyType.COYEUCAUMUA ? styleActive : styleInActive} size='small' onClick={() => setRefType(notifyType.COYEUCAUMUA)} type='text'
                >Yêu cầu mua - {countNoty.countYcm}</Button>
                <Button
                  hidden={!(user_role == role.SUPPER_ADMIN || user_role == role.ADMIN || user_role == role.ORDER || user_role == role.ORDER_MANAGER)}
                  style={refType == notifyType.COYEUCAUTACH ? styleActive : styleInActive} size='small' onClick={() => setRefType(notifyType.COYEUCAUTACH)} type='text'
                >Yêu cầu tách - {countNoty.countYct}</Button>
                <Button
                  hidden={!(user_role == role.SUPPER_ADMIN || user_role == role.ADMIN || user_role == role.ACCOUNTANT)}
                  style={refType == notifyType.COYEUCAUTHANHTOAN ? styleActive : styleInActive} size='small' onClick={() => setRefType(notifyType.COYEUCAUTHANHTOAN)} type='text'
                >Yêu cầu thanh toán - {countNoty.countYctt}</Button>
                <Button size='small' onClick={() => window.open(routerConstants.notification.list)} type='link'
                >Xem thông báo</Button>
              </Menu.Item>
              <Menu.Item key={"notification"} >
                <div style={{ overflow: "scroll", maxHeight: height - 300, }}>
                  {
                    notification.map((item, index) => {
                      return (
                        <Row key={index} style={{ marginBottom: 10 }} onClick={() => updateNofification(item)} >
                          <div style={{
                            flexDirection: 'row',
                            display: 'flex',
                            fontWeight: item.watched ? "normal" : 'bold',
                            // color: item.watched ? 'black' : "blue",
                          }}>
                            <div style={{ flex: 1, color: item.watched ? 'black' : "blue", fontSize: 16 }}>{(index + 1) + " - " + item.title}</div>
                            <div style={{ fontSize: 12 }}>{moment(item.createdAt).format('DD-MM-YYYY | HH:mm')}</div>
                          </div>
                          <div dangerouslySetInnerHTML={{ __html: item.watched ? item.content.replace("#0000ff;", "black;") : item.content }}></div>
                        </Row>
                      )
                    })
                  }
                </div>
              </Menu.Item>
            </Menu>
          }
        >
          <div style={{
            height: '35px',
            color: 'white',
            borderBottom: '1px solid grey',
            paddingLeft: '15px',
            paddingRight: '5px',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center', alignContent: 'center'
          }}>

            <div style={{ fontSize: '18px', color: 'white', height: '30px', flex: 1, justifyContent: 'center', alignContent: 'center' }}>
              {userInfo?.username}
            </div>
            <Button type='text' onClick={() => setVisible(!visible)}
              style={{
                textAlign: 'center', fontSize: '16px', color: 'red',
                height: '30px', backgroundColor: 'yellow',
                paddingLeft: '5px', paddingRight: '5px', borderRadius: 5,

                marginTop: "1px"
              }}>
              {count > 0 ? count + "  " : " "}<NotificationOutlined />
            </Button>
          </div>
        </Dropdown>

        {
          (user_role == role.SUPPER_ADMIN || user_role == role.ADMIN || user_role == role.MANAGER) && (
            <Menu className='menu'>
              <Menu.SubMenu key={"ADMIN"} title="Dữ liệu nguồn" className={pathname.includes("/admin/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-1"} className={pathname.includes(routerConstants.user.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.user.list}>User</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-2"} className={pathname.includes(routerConstants.warehouse.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.warehouse.list}>Kho</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-3"} className={pathname.includes(routerConstants.supplier.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.supplier.list}>Nhà cung cấp</Link>
                </Menu.Item>

                <Menu.Item key={"ADMIN-5"} className={pathname.includes(routerConstants.transportation.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.transportation.list}>Hình thức vận chuyển</Link>
                </Menu.Item>

                <Menu.Item key={"ADMIN-6"} className={pathname.includes(routerConstants.rate.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.rate.list}>Tỷ giá</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-7"} className={pathname.includes(routerConstants.categorie.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.categorie.list}>Nhóm hàng hóa</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key={"PRODUCT"} title="Sản phẩm" className={pathname.includes(routerConstants.product.name) ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-8"} className={pathname.includes(routerConstants.product.list) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.list}>Sản phẩm</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-9"} className={pathname.includes(routerConstants.product.listProductSupplier) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductSupplier}>Sản phẩm - NCC</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-10"} className={pathname.includes(routerConstants.product.listProductWarehouse) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductWarehouse}>Sản phẩm - Kho</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-11"} className={pathname.includes(routerConstants.product.listAll) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listAll}>Sản phẩm - NCC - Kho</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key={"ORDER"} title="Mua hàng" className={pathname.includes("/order/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ORDER-1"} className={pathname.includes(routerConstants.purchasesRequest.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.purchasesRequest.list}>Yêu cầu mua hàng</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-2"} className={pathname.includes(routerConstants.requestShare.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.requestShare.list}>Yêu cầu tách</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-3"} className={pathname.includes(routerConstants.pricing.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.pricing.list}>Báo giá</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-4"} className={pathname.includes(routerConstants.pricingConfirm.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.pricingConfirm.list}>Xác nhận báo giá</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-5"} className={pathname.includes(routerConstants.requestPayment.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.requestPayment.list}>Yêu cầu thanh toán</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-6"} className={pathname.includes(routerConstants.paymentOrder.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.paymentOrder.list}>Thanh toán</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-7"} className={pathname.includes(routerConstants.order.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.order.list}>Đơn hàng</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-8"} className={pathname.includes(routerConstants.historyOrder.list) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.historyOrder.list}>Lịch sử đặt hàng</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.Item key={"ORDER-9"} className={pathname.includes(routerConstants.container.name) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.container.list}>Gom cont</Link>
              </Menu.Item>

              <Menu.Item key={"ORDER-10"} className={pathname.includes(routerConstants.report.reportCapitalPrice) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportCapitalPrice}>Tính giá vốn</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-11"} className={pathname.includes(routerConstants.report.reportDeptList) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportDeptList}>Báo cáo công nợ</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-12"} className={pathname.includes(routerConstants.report.reportOrderStatus) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportOrderStatus}>Báo cáo đặt hàng</Link>
              </Menu.Item>

              <Menu.Item key={"ORDER-13"} className={pathname.includes(routerConstants.billOfLadingCode.name) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.billOfLadingCode.list}>Mã vận đơn</Link>
              </Menu.Item>
              <Menu.Item key={"ADMIN-01"} className={'menu-custom'} onClick={() => logOut()}>
                <div style={{ paddingLeft: 10 }}>Đăng xuất</div>
              </Menu.Item>
            </Menu>
          )
        }

        {
          user_role == role.SALE_MANAGER && (
            <Menu className='menu'>
              <Menu.SubMenu key={"ADMIN"} title="Dữ liệu nguồn" className={pathname.includes("/admin/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-7"} className={pathname.includes(routerConstants.categorie.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.categorie.list}>Nhóm hàng hóa</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key={"PRODUCT"} title="Sản phẩm" className={pathname.includes(routerConstants.product.name) ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-8"} className={pathname.includes(routerConstants.product.list) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.list}>Sản phẩm</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-9"} className={pathname.includes(routerConstants.product.listProductSupplier) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductSupplier}>Sản phẩm - NCC</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-10"} className={pathname.includes(routerConstants.product.listProductWarehouse) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductWarehouse}>Sản phẩm - Kho</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-11"} className={pathname.includes(routerConstants.product.listAll) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listAll}>Sản phẩm - NCC - Kho</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key={"ORDER"} title="Mua hàng" className={pathname.includes("/order/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ORDER-1"} className={pathname.includes(routerConstants.purchasesRequest.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.purchasesRequest.list}>Yêu cầu mua hàng</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-2"} className={pathname.includes(routerConstants.requestShare.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.requestShare.list}>Yêu cầu tách</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-4"} className={pathname.includes(routerConstants.pricingConfirm.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.pricingConfirm.list}>Xác nhận báo giá</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key={"ADMIN-01"} className={'menu-custom'} onClick={() => logOut()}>
                <div style={{ paddingLeft: 10 }}>Đăng xuất</div>
              </Menu.Item>
            </Menu>
          )
        }

        {
          user_role == role.SALE && (
            <Menu className='menu'>
              <Menu.SubMenu key={"ADMIN"} title="Dữ liệu nguồn" className={pathname.includes("/admin/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-7"} className={pathname.includes(routerConstants.categorie.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.categorie.list}>Nhóm hàng hóa</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key={"PRODUCT"} title="Sản phẩm" className={pathname.includes(routerConstants.product.name) ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-8"} className={pathname.includes(routerConstants.product.list) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.list}>Sản phẩm</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-9"} className={pathname.includes(routerConstants.product.listProductSupplier) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductSupplier}>Sản phẩm - NCC</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-10"} className={pathname.includes(routerConstants.product.listProductWarehouse) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductWarehouse}>Sản phẩm - Kho</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-11"} className={pathname.includes(routerConstants.product.listAll) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listAll}>Sản phẩm - NCC - Kho</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key={"ORDER"} title="Mua hàng" className={pathname.includes("/order/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ORDER-1"} className={pathname.includes(routerConstants.purchasesRequest.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.purchasesRequest.list}>Yêu cầu mua hàng</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key={"ADMIN-01"} className={'menu-custom'} onClick={() => logOut()}>
                <div style={{ paddingLeft: 10 }}>Đăng xuất</div>
              </Menu.Item>
            </Menu>
          )
        }

        {
          (user_role == role.ORDER_MANAGER) && (
            <Menu className='menu'>
              <Menu.SubMenu key={"ADMIN"} title="Dữ liệu nguồn" className={pathname.includes("/admin/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-3"} className={pathname.includes(routerConstants.supplier.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.supplier.list}>Nhà cung cấp</Link>
                </Menu.Item>

                <Menu.Item key={"ADMIN-5"} className={pathname.includes(routerConstants.transportation.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.transportation.list}>Hình thức vận chuyển</Link>
                </Menu.Item>

                <Menu.Item key={"ADMIN-6"} className={pathname.includes(routerConstants.rate.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.rate.list}>Tỷ giá</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-7"} className={pathname.includes(routerConstants.categorie.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.categorie.list}>Nhóm hàng hóa</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key={"PRODUCT"} title="Sản phẩm" className={pathname.includes(routerConstants.product.name) ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-8"} className={pathname.includes(routerConstants.product.list) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.list}>Sản phẩm</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-9"} className={pathname.includes(routerConstants.product.listProductSupplier) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductSupplier}>Sản phẩm - NCC</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-10"} className={pathname.includes(routerConstants.product.listProductWarehouse) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductWarehouse}>Sản phẩm - Kho</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-11"} className={pathname.includes(routerConstants.product.listAll) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listAll}>Sản phẩm - NCC - Kho</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key={"ORDER"} title="Mua hàng" className={pathname.includes("/order/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ORDER-1"} className={pathname.includes(routerConstants.purchasesRequest.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.purchasesRequest.list}>Yêu cầu mua hàng</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-2"} className={pathname.includes(routerConstants.requestShare.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.requestShare.list}>Yêu cầu tách</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-3"} className={pathname.includes(routerConstants.pricing.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.pricing.list}>Báo giá</Link>
                </Menu.Item>
                {/* <Menu.Item key={"ORDER-4"} className={pathname.includes(routerConstants.pricingConfirm.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.pricingConfirm.list}>Xác nhận báo giá</Link>
                </Menu.Item> */}
                <Menu.Item key={"ORDER-5"} className={pathname.includes(routerConstants.requestPayment.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.requestPayment.list}>Yêu cầu thanh toán</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-6"} className={pathname.includes(routerConstants.paymentOrder.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.paymentOrder.list}>Thanh toán</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-7"} className={pathname.includes(routerConstants.order.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.order.list}>Đơn hàng</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-8"} className={pathname.includes(routerConstants.historyOrder.list) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.historyOrder.list}>Lịch sử đặt hàng</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.Item key={"ORDER-9"} className={pathname.includes(routerConstants.container.name) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.container.list}>Gom cont</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-10"} className={pathname.includes(routerConstants.report.reportCapitalPrice) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportCapitalPrice}>Tính giá vốn</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-11"} className={pathname.includes(routerConstants.report.reportDeptList) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportDeptList}>Báo cáo công nợ</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-12"} className={pathname.includes(routerConstants.report.reportOrderStatus) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportOrderStatus}>Báo cáo đặt hàng</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-13"} className={pathname.includes(routerConstants.billOfLadingCode.name) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.billOfLadingCode.list}>Mã vận đơn</Link>
              </Menu.Item>

              <Menu.Item key={"ADMIN-01"} className={'menu-custom'} onClick={() => logOut()}>
                <div style={{ paddingLeft: 10 }}>Đăng xuất</div>
              </Menu.Item>
            </Menu>
          )
        }

        {
          (user_role == role.ORDER) && (
            <Menu className='menu'>
              <Menu.SubMenu key={"ADMIN"} title="Dữ liệu nguồn" className={pathname.includes("/admin/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-3"} className={pathname.includes(routerConstants.supplier.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.supplier.list}>Nhà cung cấp</Link>
                </Menu.Item>

                <Menu.Item key={"ADMIN-5"} className={pathname.includes(routerConstants.transportation.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.transportation.list}>Hình thức vận chuyển</Link>
                </Menu.Item>

                <Menu.Item key={"ADMIN-6"} className={pathname.includes(routerConstants.rate.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.rate.list}>Tỷ giá</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-7"} className={pathname.includes(routerConstants.categorie.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.categorie.list}>Nhóm hàng hóa</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key={"PRODUCT"} title="Sản phẩm" className={pathname.includes(routerConstants.product.name) ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-8"} className={pathname.includes(routerConstants.product.list) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.list}>Sản phẩm</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-9"} className={pathname.includes(routerConstants.product.listProductSupplier) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductSupplier}>Sản phẩm - NCC</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-10"} className={pathname.includes(routerConstants.product.listProductWarehouse) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductWarehouse}>Sản phẩm - Kho</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-11"} className={pathname.includes(routerConstants.product.listAll) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listAll}>Sản phẩm - NCC - Kho</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key={"ORDER"} title="Mua hàng" className={pathname.includes("/order/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ORDER-1"} className={pathname.includes(routerConstants.purchasesRequest.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.purchasesRequest.list}>Yêu cầu mua hàng</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-2"} className={pathname.includes(routerConstants.requestShare.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.requestShare.list}>Yêu cầu tách</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-3"} className={pathname.includes(routerConstants.pricing.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.pricing.list}>Báo giá</Link>
                </Menu.Item>
                {/* <Menu.Item key={"ORDER-4"} className={pathname.includes(routerConstants.pricingConfirm.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.pricingConfirm.list}>Xác nhận báo giá</Link>
                </Menu.Item> */}
                <Menu.Item key={"ORDER-5"} className={pathname.includes(routerConstants.requestPayment.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.requestPayment.list}>Yêu cầu thanh toán</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-6"} className={pathname.includes(routerConstants.paymentOrder.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.paymentOrder.list}>Thanh toán</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-7"} className={pathname.includes(routerConstants.order.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.order.list}>Đơn hàng</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-8"} className={pathname.includes(routerConstants.historyOrder.list) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.historyOrder.list}>Lịch sử đặt hàng</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.Item key={"ORDER-9"} className={pathname.includes(routerConstants.container.name) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.container.list}>Gom cont</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-10"} className={pathname.includes(routerConstants.report.reportCapitalPrice) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportCapitalPrice}>Tính giá vốn</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-11"} className={pathname.includes(routerConstants.report.reportDeptList) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportDeptList}>Báo cáo công nợ</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-12"} className={pathname.includes(routerConstants.report.reportOrderStatus) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportOrderStatus}>Báo cáo đặt hàng</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-13"} className={pathname.includes(routerConstants.billOfLadingCode.name) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.billOfLadingCode.list}>Mã vận đơn</Link>
              </Menu.Item>

              <Menu.Item key={"ADMIN-01"} className={'menu-custom'} onClick={() => logOut()}>
                <div style={{ paddingLeft: 10 }}>Đăng xuất</div>
              </Menu.Item>
            </Menu>
          )
        }

        {
          (user_role == role.ACCOUNTANT) && (
            <Menu className='menu'>
              <Menu.SubMenu key={"ADMIN"} title="Dữ liệu nguồn" className={pathname.includes("/admin/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-3"} className={pathname.includes(routerConstants.supplier.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.supplier.list}>Nhà cung cấp</Link>
                </Menu.Item>

                <Menu.Item key={"ADMIN-5"} className={pathname.includes(routerConstants.transportation.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.transportation.list}>Hình thức vận chuyển</Link>
                </Menu.Item>

                <Menu.Item key={"ADMIN-6"} className={pathname.includes(routerConstants.rate.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.rate.list}>Tỷ giá</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-7"} className={pathname.includes(routerConstants.categorie.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.categorie.list}>Nhóm hàng hóa</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key={"PRODUCT"} title="Sản phẩm" className={pathname.includes(routerConstants.product.name) ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ADMIN-8"} className={pathname.includes(routerConstants.product.list) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.list}>Sản phẩm</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-9"} className={pathname.includes(routerConstants.product.listProductSupplier) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductSupplier}>Sản phẩm - NCC</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-10"} className={pathname.includes(routerConstants.product.listProductWarehouse) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listProductWarehouse}>Sản phẩm - Kho</Link>
                </Menu.Item>
                <Menu.Item key={"ADMIN-11"} className={pathname.includes(routerConstants.product.listAll) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.product.listAll}>Sản phẩm - NCC - Kho</Link>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu key={"ORDER"} title="Mua hàng" className={pathname.includes("/order/") ? 'menu-custom-active' : 'menu-custom'} popupClassName='popup-menu-custom'>
                <Menu.Item key={"ORDER-3"} className={pathname.includes(routerConstants.pricing.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.pricing.list}>Báo giá</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-5"} className={pathname.includes(routerConstants.requestPayment.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.requestPayment.list}>Yêu cầu thanh toán</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-6"} className={pathname.includes(routerConstants.paymentOrder.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.paymentOrder.list}>Thanh toán</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-7"} className={pathname.includes(routerConstants.order.name) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.order.list}>Đơn hàng</Link>
                </Menu.Item>
                <Menu.Item key={"ORDER-8"} className={pathname.includes(routerConstants.historyOrder.list) ? 'sub-menu-custom-active' : ''}>
                  <Link to={routerConstants.historyOrder.list}>Lịch sử đặt hàng</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.Item key={"ORDER-9"} className={pathname.includes(routerConstants.container.name) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.container.list}>Gom cont</Link>
              </Menu.Item>

              <Menu.Item key={"ORDER-10"} className={pathname.includes(routerConstants.report.reportCapitalPrice) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportCapitalPrice}>Tính giá vốn</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-11"} className={pathname.includes(routerConstants.report.reportDeptList) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportDeptList}>Báo cáo công nợ</Link>
              </Menu.Item>
              <Menu.Item key={"ORDER-12"} className={pathname.includes(routerConstants.report.reportOrderStatus) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.report.reportOrderStatus}>Báo cáo đặt hàng</Link>
              </Menu.Item>

              <Menu.Item key={"ORDER-13"} className={pathname.includes(routerConstants.billOfLadingCode.name) ? 'menu-custom-active' : 'menu-custom'}>
                <Link to={routerConstants.billOfLadingCode.list}>Mã vận đơn</Link>
              </Menu.Item>

              <Menu.Item key={"ADMIN-01"} className={'menu-custom'} onClick={() => logOut()}>
                <div style={{ paddingLeft: 10 }}>Đăng xuất</div>
              </Menu.Item>
            </Menu>
          )
        }

      </Sider>
      <Layout className="site-layout">
        <Header className='header-custom' style={{ padding: 0, height: '0px' }}>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            minHeight: "100vh"
          }}
        >
          <div className="basic-layout-content" style={{
            minHeight: "100vh"
          }}
          >{children}</div>
        </Content>
      </Layout>
    </Layout >
  )
}

export default BasicLayout
