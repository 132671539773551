import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface CommonState {
  isLoading: boolean
  triggerTime: undefined | number
  rateCnyToVnd: number
}

const initialState: CommonState = {
  isLoading: false,
  triggerTime: 0,
  rateCnyToVnd: 3425.18,
}

export const commonDucks = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isLoading = action.payload
    },
    setTriggerTime: (state: RootState, action: PayloadAction<any>) => {
      state.triggerTime = action.payload
    },
    setIsSignUp: (state: RootState, action: PayloadAction<any>) => {
      state.isSignUp = action.payload
    },
    setOpenShowImages: (state: RootState, action: PayloadAction<any>) => {
      state.isOpenShowImages = action.payload
    },
    setOpenUploadImages: (state: RootState, action: PayloadAction<any>) => {
      state.isOpenUploadImages = action.payload
    },
    setRateCnyToVnd: (state: RootState, action: PayloadAction<number>) => {
      state.rateCnyToVnd = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoading, setTriggerTime, setIsSignUp, setOpenShowImages, setOpenUploadImages, setRateCnyToVnd } =
  commonDucks.actions

export default commonDucks.reducer
