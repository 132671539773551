import { call, put, takeEvery } from 'redux-saga/effects'
import * as warehouseActions from '../ducks/warehouseDucks'
import * as warehouseApi from '../../api/warehouseApi'
import * as queryString from 'query-string'
import { warehouseType } from '../../constants/commonConstants'



interface FetchWarehouseListSaga {
  type: string
  payload: string
}

interface FetchWarehouseDetailSaga {
  type: string
  payload: string
}

function* fetchListWarehouse({ payload: queryString }: FetchWarehouseListSaga) {
  try {
    const res: ReturnType<typeof warehouseApi.getListWarehouse> = yield call(warehouseApi.getListWarehouse, queryString)
    yield put(warehouseActions.getListWarehouseSuccess(res))
  } catch (error) {
    yield put(warehouseActions.getListWarehouseFail(error))
  }
}

function* fetchDetailWarehouse({ payload: id }: FetchWarehouseDetailSaga) {
  try {
    const res: ReturnType<typeof warehouseApi.getDetailWarehouse> = yield call(warehouseApi.getDetailWarehouse, id)
    yield put(warehouseActions.getDetailWarehouseSuccess(res))
  } catch (error) {
    yield put(warehouseActions.getDetailWarehouseFail(error))
  }
}

function* fetchListSourceWarehouse() {
  const stringified = queryString.stringify({ page: 1, page_size: 2000, loai: warehouseType.source }, { skipEmptyString: true })
  try {
    const res: ReturnType<typeof warehouseApi.getListWarehouse> = yield call(warehouseApi.getListWarehouse, stringified)
    yield put(warehouseActions.getListSourceWarehouseSuccess(res))
  } catch (error) {
    yield put(warehouseActions.getListSourceWarehouseFail(error))
  }
}

function* fetchListDesWarehouse() {
  const stringified = queryString.stringify({ page: 1, page_size: 2000, loai: warehouseType.des }, { skipEmptyString: true })
  try {
    const res: ReturnType<typeof warehouseApi.getListWarehouse> = yield call(warehouseApi.getListWarehouse, stringified)
    yield put(warehouseActions.getListDesWarehouseSuccess(res))
  } catch (error) {
    yield put(warehouseActions.getListDesWarehouseFail(error))
  }
}



export default function* watchWarehouse() {
  yield takeEvery(warehouseActions.getListWarehouse.toString(), fetchListWarehouse)
  yield takeEvery(warehouseActions.getDetailWarehouse.toString(), fetchDetailWarehouse)
  yield takeEvery(warehouseActions.getListSourceWarehouse.toString(), fetchListSourceWarehouse)
  yield takeEvery(warehouseActions.getListDesWarehouse.toString(), fetchListDesWarehouse)

}
