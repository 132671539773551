import { call, put, takeEvery } from 'redux-saga/effects'
import * as userActions from '../ducks/userDucks'
import * as userApi from '../../api/userApi'

interface FetchLoginSaga {
  type: string
  payload: { username: string; password: string }
}

interface FetchUserListSaga {
  type: string
  payload: string
}


interface FetchUserDetailSaga {
  type: string
  payload: string
}

function* fetchLogin({ payload: { username, password } }: FetchLoginSaga) {
  try {
    const res: ReturnType<typeof userApi.login> = yield call(userApi.login, username, password)
    yield put(userActions.loginSuccess(res))
  } catch (error) {
    yield put(userActions.loginFail(error))
  }
}


function* fetchListUser({ payload: queryString }: FetchUserListSaga) {
  try {
    const res: ReturnType<typeof userApi.getListUser> = yield call(userApi.getListUser, queryString)
    yield put(userActions.getListUserSuccess(res))
  } catch (error) {
    yield put(userActions.getListUserFail(error))
  }
}

function* fetchDetailUser({ payload: id }: FetchUserDetailSaga) {
  try {
    const res: ReturnType<typeof userApi.getDetailUser> = yield call(userApi.getDetailUser, id)
    yield put(userActions.getDetailUserSuccess(res))
  } catch (error) {
    yield put(userActions.getDetailUserFail(error))
  }
}


function* fetchListRole({ payload: query }: any) {
  try {
    const res: ReturnType<typeof userApi.login> = yield call(userApi.getListRole, query)
    yield put(userActions.getListRoleSuccess(res))
  } catch (error) {
    yield put(userActions.getListRoleFail(error))
  }
}



function* fetchListUserByRole({ payload: queryString }: FetchUserListSaga) {
  try {
    const res: ReturnType<typeof userApi.getListUser> = yield call(userApi.getListUser, queryString)
    yield put(userActions.getListUserByRoleSuccess(res))
  } catch (error) {
    yield put(userActions.getListUserByRoleFail(error))
  }
}


function* fetchListProject() {
  try {
    const res: ReturnType<typeof userApi.getListProject> = yield call(userApi.getListProject)
    yield put(userActions.getListProjectSuccess(res))
  } catch (error) {
    yield put(userActions.getListProjectFail(error))
  }
}






export default function* watchUser() {
  yield takeEvery(userActions.login.toString(), fetchLogin)
  yield takeEvery(userActions.getListUser.toString(), fetchListUser)
  yield takeEvery(userActions.getDetailUser.toString(), fetchDetailUser)
  yield takeEvery(userActions.getListRole.toString(), fetchListRole)
  yield takeEvery(userActions.getListUserByRole.toString(), fetchListUserByRole)
  yield takeEvery(userActions.getListProject.toString(), fetchListProject)

}
