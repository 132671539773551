import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface RateState {
  rateList?: any
  formList?: any
  rateDetail?: any
  formUniquieList?: any

}

const initialState: RateState = {
  rateList: {},
  formList: {},
  rateDetail: {},
  formUniquieList: {},

}

export const rateDucks = createSlice({
  name: 'rate',
  initialState,
  reducers: {
   
    getListRate: (state: RootState, action: PayloadAction<any>) => {
      state.rateList = { loading: true, queryStr: action.payload }
    },
    getListRateSuccess: (state, action: PayloadAction<any>) => {
      state.rateList = { loading: false, ...action.payload }
    },
    getListRateFail: (state, action: PayloadAction<any>) => {
      state.rateList = { loading: false, ...action.payload }
    },

    getListForm: (state: RootState, action: PayloadAction<any>) => {
      state.formList = { loading: true, queryStr: action.payload }
    },
    getListFormSuccess: (state, action: PayloadAction<any>) => {
      state.formList = { loading: false, ...action.payload }
    },
    getListFormFail: (state, action: PayloadAction<any>) => {
      state.formList = { loading: false, ...action.payload }
    },


    getListFormUniquie: (state: RootState, action: PayloadAction<any>) => {
      state.formUniquieList = { loading: true, queryStr: action.payload }
    },
    getListFormUniquieSuccess: (state, action: PayloadAction<any>) => {
      state.formUniquieList = { loading: false, ...action.payload }
    },
    getListFormUniquieFail: (state, action: PayloadAction<any>) => {
      state.formUniquieList = { loading: false, ...action.payload }
    },

    getDetailRate: (state: RootState, action: PayloadAction<any>) => {
      state.rateDetail = { loading: true, id: action.payload }
    },
    getDetailRateSuccess: (state, action: PayloadAction<any>) => {
      state.rateDetail = { loading: false, ...action.payload }
    },
    getDetailRateFail: (state, action: PayloadAction<any>) => {
      state.rateDetail = { loading: false, ...action.payload }
    },

    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListRate,
  getListRateSuccess,
  getListRateFail,
  getListForm,
  getListFormSuccess,
  getListFormFail,
  getListFormUniquie,
  getListFormUniquieSuccess,
  getListFormUniquieFail,
  getDetailRate,
  getDetailRateSuccess,
  getDetailRateFail,
} = rateDucks.actions

export default rateDucks.reducer
