import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface PurchasesRequestState {
  purchasesRequestList?: any
  purchasesRequestDetail?: any
}

const initialState: PurchasesRequestState = {
  purchasesRequestList: {},
  purchasesRequestDetail: {},
 
}

export const purchasesRequestDucks = createSlice({
  name: 'purchasesRequest',
  initialState,
  reducers: {

    getListPurchasesRequest: (state: RootState, action: PayloadAction<any>) => {
      state.purchasesRequestList = { loading: true, queryStr: action.payload }
    },
    getListPurchasesRequestSuccess: (state, action: PayloadAction<any>) => {
      state.purchasesRequestList = { loading: false, ...action.payload }
    },
    getListPurchasesRequestFail: (state, action: PayloadAction<any>) => {
      state.purchasesRequestList = { loading: false, ...action.payload }
    },

    getDetailPurchasesRequest: (state: RootState, action: PayloadAction<any>) => {
      state.purchasesRequestDetail = { loading: true, id: action.payload }
    },
    getDetailPurchasesRequestSuccess: (state, action: PayloadAction<any>) => {
      state.purchasesRequestDetail = { loading: false, ...action.payload }
    },
    getDetailPurchasesRequestFail: (state, action: PayloadAction<any>) => {
      state.purchasesRequestDetail = { loading: false, ...action.payload }
    },

  },
})

// Action creators are generated for each case reducer function
export const {
  getListPurchasesRequest,
  getListPurchasesRequestSuccess,
  getListPurchasesRequestFail,
  getDetailPurchasesRequest,
  getDetailPurchasesRequestSuccess,
  getDetailPurchasesRequestFail,
  
} = purchasesRequestDucks.actions

export default purchasesRequestDucks.reducer
