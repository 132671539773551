import { call, put, takeEvery } from 'redux-saga/effects'
import * as settingActions from '../ducks/settingDucks'
import * as settingApi from '../../api/settingApi'

interface FetchSettingSaga {
  type: string
  payload: string
}

function* fetchListUnit({ payload: queryString }: FetchSettingSaga) {
  try {
    const res: ReturnType<typeof settingApi.getListUnit> = yield call(settingApi.getListUnit, queryString)
    yield put(settingActions.getListUnitSuccess(res))
  } catch (error) {
    yield put(settingActions.getListUnitFail(error))
  }
}


function* fetchListUnitReport({ payload: queryString }: FetchSettingSaga) {
  try {
    const res: ReturnType<typeof settingApi.getListUnitReport> = yield call(settingApi.getListUnitReport, queryString)
    yield put(settingActions.getListUnitReportSuccess(res))
  } catch (error) {
    yield put(settingActions.getListUnitReportFail(error))
  }
}



function* fetchListCategorie({ payload: queryString }: FetchSettingSaga) {
  try {
    const res: ReturnType<typeof settingApi.getListCategorie> = yield call(settingApi.getListCategorie, queryString)
    yield put(settingActions.getListCategorieSuccess(res))
  } catch (error) {
    yield put(settingActions.getListCategorieFail(error))
  }
}



function* fetchListSourceWarehouse({ payload: queryString }: FetchSettingSaga) {
  try {
    const res: ReturnType<typeof settingApi.getListWareHouse> = yield call(settingApi.getListWareHouse, queryString)
    yield put(settingActions.getListSourceWarehouseSuccess(res))
  } catch (error) {
    yield put(settingActions.getListSourceWarehouseFail(error))
  }
}


function* fetchListDestinWarehouse({ payload: queryString }: FetchSettingSaga) {
  try {
    const res: ReturnType<typeof settingApi.getListWareHouse> = yield call(settingApi.getListWareHouse, queryString)
    yield put(settingActions.getListDestinWarehouseSuccess(res))
  } catch (error) {
    yield put(settingActions.getListDestinWarehouseFail(error))
  }
}


export default function* watchSetting() {
  yield takeEvery(settingActions.getListUnit.toString(), fetchListUnit)
  yield takeEvery(settingActions.getListUnitReport.toString(), fetchListUnitReport)
  yield takeEvery(settingActions.getListCategorie.toString(), fetchListCategorie)
  yield takeEvery(settingActions.getListSourceWarehouse.toString(), fetchListSourceWarehouse)
  yield takeEvery(settingActions.getListDestinWarehouse.toString(), fetchListDestinWarehouse)
}
