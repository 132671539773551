export default {
    // Admin
    user: {
        name: 'admin/user',
        list: '/admin/user/list',
        add: '/admin/user/add',
        edit: '/admin/user/edit/:id',
        detail: '/admin/user/detail/:id',
    },
    warehouse: {
        name: 'admin/warehouse',
        list: '/admin/warehouse/list',
        add: '/admin/warehouse/add',
        edit: '/admin/warehouse/edit/:id',
        detail: '/admin/warehouse/detail/:id',
    },

    transProvider: {
        name: 'admin/trans-provider',
        list: '/admin/trans-provider/list',
        add: '/admin/trans-provider/add',
        edit: '/admin/trans-provider/edit/:id',
        detail: '/admin/trans-provider/detail/:id',
    },
    currencyProvider: {
        name: 'admin/currency-provider',
        list: '/admin/currency-provider/list',
        add: '/admin/currency-provider/add',
        edit: '/admin/currency-provider/edit/:id',
        detail: '/admin/currency-provider/detail/:id',
    },
    taxProvider: {
        name: 'admin/tax-provider',
        list: '/admin/tax-provider/list',
        add: '/admin/tax-provider/add',
        edit: '/admin/tax-provider/edit/:id',
        detail: '/admin/tax-provider/detail/:id',
    },
    
    supplier: {
        name: 'admin/supplier',
        list: '/admin/supplier/list',
        add: '/admin/supplier/add',
        edit: '/admin/supplier/edit/:id',
        detail: '/admin/supplier/detail/:id',
        import: '/admin/supplier/import',
    },
   
    transportation: {
        name: 'admin/transportation',
        list: '/admin/transportation/list',
        add: '/admin/transportation/add',
        edit: '/admin/transportation/edit/:id',
        detail: '/admin/transportation/detail/:id',
        import: '/admin/transportation/import',
    },
    container: {
        name: 'order-container/container',
        list: '/order-container/container/list',
        add: '/order-container/container/add',
        edit: '/order-container/container/edit/:id',
        updateInfo: '/order-container/container/update-info/:id',
        detail: '/order-container/container/detail/:id',
        import: '/order-container/container/import',
    },
    rate: {
        name: 'admin/rate',
        list: '/admin/rate/list',
        add: '/admin/rate/add',
        edit: '/admin/rate/edit/:id',
        detail: '/admin/rate/detail/:id',
    },
    product: {
        name: 'admin-product/product',
        list: '/admin-product/product/list',
        listProductSupplier: '/admin-product/product/product-supplier-list',
        listProductWarehouse: '/admin-product/product/product-warehouse-list',
        listAll: '/admin-product/product/product-supplier-warehouse-list',
        add: '/admin-product/product/add',
        edit: '/admin-product/product/edit/:id',
        detail: '/admin-product/product/detail/:id',
        import: '/admin-product/product/import',
    },
    categorie: {
        name: 'admin/categorie',
        list: '/admin/categorie/list',
        add: '/admin/categorie/add',
        edit: '/admin/categorie/edit/:id',
        detail: '/admin/categorie/detail/:id',
        import: '/admin/categorie/import',
    },
    productGroup: {
        name: 'admin/product-group',
        list: '/admin/product-group/list',
        add: '/admin/product-group/add',
        edit: '/admin/product-group/edit/:id',
        detail: '/admin/product-group/detail/:id',
        import: '/admin/product-group/import',
    },


    // yêu cầu mua hàng
    purchasesRequest: {
        name: 'order/purchases-request',
        list: '/order/purchases-request/list',
        add: '/order/purchases-request/add',
        edit: '/order/purchases-request/edit/:id',
        detail: '/order/purchases-request/detail/:id',
        import: '/order/purchases-request/import',
    },
    // yêu cầu tách
    requestShare: {
        name: 'order/request-share',
        list: '/order/request-share/list',
        add: '/order/request-share/add',
        edit: '/order/request-share/edit/:id',
        detail: '/order/request-share/detail/:id',
        import: '/order/request-share/import',
    },
    // báo giá
    pricing: {
        name: 'order/pricing/',
        list: '/order/pricing/list',
        add: '/order/pricing/add',
        edit: '/order/pricing/edit/:id',
        detail: '/order/pricing/detail/:id',
    },
    // Xác nhận báo giá
    pricingConfirm: {
        name: 'order/pricing-confirm',
        list: '/order/pricing-confirm/list',
        add: '/order/pricing-confirm/add',
        edit: '/order/pricing-confirm/edit/:id',
        detail: '/order/pricing-confirm/detail/:id',
    },
    // đơn hàng
    order: {
        name: 'order/order',
        list: '/order/order/list',
        add: '/order/order/add',
        edit: '/order/order/edit/:id',
        detail: '/order/order/detail/:id',
        updateReality: '/order/order/update-reality/:id',

    },
    // yêu cầu thanh toán
    requestPayment: {
        name: 'order/request-payment',
        list: '/order/request-payment/list',
        add: '/order/request-payment/add',
        edit: '/order/request-payment/edit/:id',
        detail: '/order/request-payment/detail/:id',
    },
    // thanh toán
    paymentOrder: {
        name: 'order/payment-order',
        list: '/order/payment-order/list',
        add: '/order/payment-order/add',
        edit: '/order/payment-order/edit/:id',
        detail: '/order/payment-order/detail/:id',
    },

    // báo cáo
    // thanh toán
    report: {
        name: 'report',
        reportCapitalPrice: '/report/report-capital-price',
        reportDeptDetail: '/report/report-dept-list/:id',
        reportDeptList: '/report/report-dept-list',
        reportOrderStatus: '/report/report-order-status',
        
    },

    notification: {
        name: 'notification',
        list: '/notification/list',
    },
    historyOrder: {
        name: 'history-order',
        list: '/history-order/list',
        detail: '/history-order/detail/:id',

    },

    billOfLadingCode: {
        name: 'bill-of-lading-code',
        list: '/bill-of-lading-code/list',
        import: '/bill-of-lading-code/import',
    },

    test: {
        name: 'admin/test',
        list: '/admin/test/list',
    },
}
