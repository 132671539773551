import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface ContainerState {
  containerList?: any
  containerDetail?: any
  requestCollectList?: any
}

const initialState: ContainerState = {
  containerList: {},
  containerDetail: {},
  requestCollectList: {},
}

export const containerDucks = createSlice({
  name: 'container',
  initialState,
  reducers: {
   
    getListContainer: (state: RootState, action: PayloadAction<any>) => {
      state.containerList = { loading: true, queryStr: action.payload }
    },
    getListContainerSuccess: (state, action: PayloadAction<any>) => {
      state.containerList = { loading: false, ...action.payload }
    },
    getListContainerFail: (state, action: PayloadAction<any>) => {
      state.containerList = { loading: false, ...action.payload }
    },
    getDetailContainer: (state: RootState, action: PayloadAction<any>) => {
      state.containerDetail = { loading: true, orderId: action.payload }
    },
    getDetailContainerSuccess: (state, action: PayloadAction<any>) => {
      state.containerDetail = { loading: false, ...action.payload }
    },
    getDetailContainerFail: (state, action: PayloadAction<any>) => {
      state.containerDetail = { loading: false, ...action.payload }
    },

    getListRequestCollect: (state: RootState, action: PayloadAction<any>) => {
      state.requestCollectList = { loading: true, queryStr: action.payload }
    },
    getListRequestCollectSuccess: (state, action: PayloadAction<any>) => {
      state.requestCollectList = { loading: false, ...action.payload }
    },
    getListRequestCollectFail: (state, action: PayloadAction<any>) => {
      state.requestCollectList = { loading: false, ...action.payload }
    },

  },
})

// Action creators are generated for each case reducer function
export const {
  getListContainer,
  getListContainerSuccess,
  getListContainerFail,
  getDetailContainer,
  getDetailContainerSuccess,
  getDetailContainerFail,

  getListRequestCollect,
  getListRequestCollectSuccess,
  getListRequestCollectFail,
} = containerDucks.actions

export default containerDucks.reducer
