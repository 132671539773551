import { call, put, takeEvery } from 'redux-saga/effects'
import * as requestPaymentActions from '../ducks/requestPaymentDucks'
import * as requestPaymentApi from '../../api/requestPaymentApi'

interface FetchRequestShareListSaga {
  type: string
  payload: string
}

interface FetchRequestShareDetailSaga {
  type: string
  payload: string
}

function* fetchListRequestShare({ payload: queryString }: FetchRequestShareListSaga) {
  try {
    const res: ReturnType<typeof requestPaymentApi.getListRequestPayment> = yield call(requestPaymentApi.getListRequestPayment, queryString)
    yield put(requestPaymentActions.getListRequestPaymentSuccess(res))
  } catch (error) {
    yield put(requestPaymentActions.getListRequestPaymentFail(error))
  }
}

function* fetchDetailRequestShare({ payload: id }: FetchRequestShareDetailSaga) {
  try {
    const res: ReturnType<typeof requestPaymentApi.getDetailRequestPayment> = yield call(requestPaymentApi.getDetailRequestPayment, id)
    yield put(requestPaymentActions.getDetailRequestPaymentSuccess(res))
  } catch (error) {
    yield put(requestPaymentActions.getDetailRequestPaymentFail(error))
  }
}

export default function* watchRequestShare() {
  yield takeEvery(requestPaymentActions.getListRequestPayment.toString(), fetchListRequestShare)
  yield takeEvery(requestPaymentActions.getDetailRequestPayment.toString(), fetchDetailRequestShare)
}
