import { call, put, takeEvery } from 'redux-saga/effects'
import * as containerActions from '../ducks/containerDucks'
import * as containerApi from '../../api/containerApi'

interface FetchContainerListSaga {
  type: string
  payload: string
}

interface FetchContainerDetailSaga {
  type: string
  payload: string
}

interface FetchRequestCollectListSaga {
  type: string
  payload: string
}

function* fetchListContainer({ payload: queryString }: FetchContainerListSaga) {
  try {
    const res: ReturnType<typeof containerApi.getListContainer> = yield call(containerApi.getListContainer, queryString)
    yield put(containerActions.getListContainerSuccess(res))
  } catch (error) {
    yield put(containerActions.getListContainerFail(error))
  }
}

function* fetchDetailContainer({ payload: id }: FetchContainerDetailSaga) {
  try {
    const res: ReturnType<typeof containerApi.getDetailContainer> = yield call(containerApi.getDetailContainer, id)
    yield put(containerActions.getDetailContainerSuccess(res))
  } catch (error) {
    yield put(containerActions.getDetailContainerFail(error))
  }
}

function* fetchRequestCollect({ payload: queryString }: FetchRequestCollectListSaga) {
  try {
    const res: ReturnType<typeof containerApi.getListRequestCollect> = yield call(containerApi.getListRequestCollect, queryString)
    yield put(containerActions.getListRequestCollectSuccess(res))
  } catch (error) {
    yield put(containerActions.getListRequestCollectFail(error))
  }
}

export default function* watchContainer() {
  yield takeEvery(containerActions.getListContainer.toString(), fetchListContainer)
  yield takeEvery(containerActions.getDetailContainer.toString(), fetchDetailContainer)
  yield takeEvery(containerActions.getListRequestCollect.toString(), fetchRequestCollect)
}
