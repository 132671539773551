import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface NotificationState {
  notificationList?: any
  notificationDetail?: any
  notificationAllList?: any
}

const initialState: NotificationState = {
  notificationList: {},
  notificationDetail: {},
}

export const rateDucks = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    getListNotification: (state: RootState, action: PayloadAction<any>) => {
      state.notificationList = { loading: true, queryStr: action.payload }
    },
    getListNotificationSuccess: (state, action: PayloadAction<any>) => {
      state.notificationList = { loading: false, ...action.payload }
    },
    getListNotificationFail: (state, action: PayloadAction<any>) => {
      state.notificationList = { loading: false, ...action.payload }
    },

    getListAllNotification: (state: RootState, action: PayloadAction<any>) => {
      state.notificationAllList = { loading: true, queryStr: action.payload }
    },
    getListAllNotificationSuccess: (state, action: PayloadAction<any>) => {
      state.notificationAllList = { loading: false, ...action.payload }
    },
    getListAllNotificationFail: (state, action: PayloadAction<any>) => {
      state.notificationAllList = { loading: false, ...action.payload }
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListNotification,
  getListNotificationSuccess,
  getListNotificationFail,

  getListAllNotification,
  getListAllNotificationSuccess,
  getListAllNotificationFail,
} = rateDucks.actions

export default rateDucks.reducer
