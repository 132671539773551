import ApisService from './index'
import { ITaxProviderAdd, ITaxProviderUpdate } from '../interfaces/taxProviderInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createTaxProvider(data: ITaxProviderAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/nhacungcap-thue/create-nhacungcap-thue', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateTaxProvider(data: ITaxProviderUpdate) {
  try {
    return ApisService.put('/muahang-backend/v1/nhacungcap-thue/' + data.id + '/update-nhacungcap-thue', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailTaxProvider(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/nhacungcap-thue/' + id + '/get-chitiet-nhacungcap-thue')
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function getListTaxProvider(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/nhacungcap-thue/filter-nhacungcap-thue', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteTaxProvider(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/nhacungcap-thue/' + id + '/delete-nhacungcap-thue')
  } catch (err) {
    handleError(err)
    throw err
  }
}
