import React, { Suspense } from 'react'
import './assets/styles/App.scss'
import 'antd/dist/antd.min.css'
import './assets/styles/custom.scss'
import routes from './router'
import { Route, Switch, Redirect } from 'react-router-dom'
import { isLoggedIn } from './utils/userUtils'

const App: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {routes.map(({ component: Component, path, exact, name, layout: Layout }) => (
          <Route
            key={name}
            exact={exact}
            path={path}
            render={(props: any) => {
              return (
                <Layout>
                  {isLoggedIn() ? (
                    path === '/login' ? (
                      <Redirect push to="/" />
                    ) : (
                      <Component {...props} />
                    )
                  ) : path === '/login' ? (
                    <Component {...props} />
                  ) : (
                    <Redirect push to="/login" />
                  )}
                </Layout>
              )
            }}
          />
        ))}
      </Switch>
    </Suspense>
  )
}

export default App
