import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface SupplierState {
  supplierList?: any
  supplierDetail?: any
}

const initialState: SupplierState = {
  supplierList: {},
  supplierDetail: {},
}

export const supplierDucks = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
   
    getListSupplier: (state: RootState, action: PayloadAction<any>) => {
      state.supplierList = { loading: true, queryStr: action.payload }
    },
    getListSupplierSuccess: (state, action: PayloadAction<any>) => {
      state.supplierList = { loading: false, ...action.payload }
    },
    getListSupplierFail: (state, action: PayloadAction<any>) => {
      state.supplierList = { loading: false, ...action.payload }
    },
    
    getDetailSupplier: (state: RootState, action: PayloadAction<any>) => {
      state.supplierDetail = { loading: true, id: action.payload }
    },
    getDetailSupplierSuccess: (state, action: PayloadAction<any>) => {
      state.supplierDetail = { loading: false, ...action.payload }
    },
    getDetailSupplierFail: (state, action: PayloadAction<any>) => {
      state.supplierDetail = { loading: false, ...action.payload }
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListSupplier,
  getListSupplierSuccess,
  getListSupplierFail,
  getDetailSupplier,
  getDetailSupplierSuccess,
  getDetailSupplierFail,
} = supplierDucks.actions

export default supplierDucks.reducer
