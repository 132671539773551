import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AdminState {
  isOpenImportProduct?: boolean
}

const initialState: AdminState = {
  isOpenImportProduct: false,
}

export const poupDucks = createSlice({
  name: 'poup',
  initialState,
  reducers: {
    setOpenImportProduct: (state, action: PayloadAction<any>) => {
      state.isOpenImportProduct = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setOpenImportProduct,
}: any = poupDucks.actions

export default poupDucks.reducer
