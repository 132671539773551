import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface OrderState {
  orderList?: any
  orderDetail?: any
  requestList?: any
  reportCapitalPriceList?: any
  reportDeptList?: any
  reportDeptDetail?: any
  reportOrderStatusList?: any
  historyOrderList?: any
  billOfLadingCodeList?: any
}

const initialState: OrderState = {
  orderList: {},
  orderDetail: {},
  requestList: {},
  reportCapitalPriceList: {},
  reportDeptList: {},
  reportDeptDetail: {},
  reportOrderStatusList: {},
  historyOrderList: {},
  billOfLadingCodeList: {},
}

export const orderDucks = createSlice({
  name: 'rate',
  initialState,
  reducers: {

    getListOrder: (state: RootState, action: PayloadAction<any>) => {
      state.orderList = { loading: true, queryStr: action.payload }
    },
    getListOrderSuccess: (state, action: PayloadAction<any>) => {
      state.orderList = { loading: false, ...action.payload }
    },
    getListOrderFail: (state, action: PayloadAction<any>) => {
      state.orderList = { loading: false, ...action.payload }
    },

    getDetailOrder: (state: RootState, action: PayloadAction<any>) => {
      state.orderDetail = { loading: true, id: action.payload }
    },
    getDetailOrderSuccess: (state, action: PayloadAction<any>) => {
      state.orderDetail = { loading: false, ...action.payload }
    },
    getDetailOrderFail: (state, action: PayloadAction<any>) => {
      state.orderDetail = { loading: false, ...action.payload }
    },

    getListRequest: (state: RootState, action: PayloadAction<any>) => {
      state.requestList = { loading: true, queryStr: action.payload }
    },
    getListRequestSuccess: (state, action: PayloadAction<any>) => {
      state.requestList = { loading: false, ...action.payload }
    },
    getListRequestFail: (state, action: PayloadAction<any>) => {
      state.requestList = { loading: false, ...action.payload }
    },
    // báo cáo
    getListReportCapitalPrice: (state: RootState, action: PayloadAction<any>) => {
      state.reportCapitalPriceList = { loading: true, queryStr: action.payload }
    },
    getListReportCapitalPriceSuccess: (state, action: PayloadAction<any>) => {
      state.reportCapitalPriceList = { loading: false, ...action.payload }
    },
    getListReportCapitalPriceFail: (state, action: PayloadAction<any>) => {
      state.reportCapitalPriceList = { loading: false, ...action.payload }
    },

    getListReportDeptList: (state: RootState, action: PayloadAction<any>) => {
      state.reportDeptList = { loading: true, queryStr: action.payload }
    },
    getListReportDeptListSuccess: (state, action: PayloadAction<any>) => {
      state.reportDeptList = { loading: false, ...action.payload }
    },
    getListReportDeptListFail: (state, action: PayloadAction<any>) => {
      state.reportDeptList = { loading: false, ...action.payload }
    },

    getListReportDeptDetail: (state: RootState, action: PayloadAction<any>) => {
      state.reportDeptDetail = { loading: true, queryStr: action.payload }
    },
    getListReportDeptDetailSuccess: (state, action: PayloadAction<any>) => {
      state.reportDeptDetail = { loading: false, ...action.payload }
    },
    getListReportDeptDetailFail: (state, action: PayloadAction<any>) => {
      state.reportDeptDetail = { loading: false, ...action.payload }
    },


    getListReportOrderStatus: (state: RootState, action: PayloadAction<any>) => {
      state.reportOrderStatusList = { loading: true, queryStr: action.payload }
    },
    getListReportOrderStatusSuccess: (state, action: PayloadAction<any>) => {
      state.reportOrderStatusList = { loading: false, ...action.payload }
    },
    getListReportOrderStatusFail: (state, action: PayloadAction<any>) => {
      state.reportOrderStatusList = { loading: false, ...action.payload }
    },

    getListHistoryOrder: (state: RootState, action: PayloadAction<any>) => {
      state.historyOrderList = { loading: true, queryStr: action.payload }
    },
    getListHistoryOrderSuccess: (state, action: PayloadAction<any>) => {
      state.historyOrderList = { loading: false, ...action.payload }
    },
    getListHistoryOrderFail: (state, action: PayloadAction<any>) => {
      state.historyOrderList = { loading: false, ...action.payload }
    },


    getListBillOfLadingCode: (state: RootState, action: PayloadAction<any>) => {
      state.billOfLadingCodeList = { loading: true, queryStr: action.payload }
    },
    getListBillOfLadingCodeSuccess: (state, action: PayloadAction<any>) => {
      state.billOfLadingCodeList = { loading: false, ...action.payload }
    },
    getListBillOfLadingCodeFail: (state, action: PayloadAction<any>) => {
      state.billOfLadingCodeList = { loading: false, ...action.payload }
    },

  },
})

// Action creators are generated for each case reducer function
export const {
  getListOrder,
  getListOrderSuccess,
  getListOrderFail,
  getDetailOrder,
  getDetailOrderSuccess,
  getDetailOrderFail,
  getListRequest,
  getListRequestSuccess,
  getListRequestFail,
  // báo cáo
  getListReportCapitalPrice,
  getListReportCapitalPriceSuccess,
  getListReportCapitalPriceFail,

  getListReportDeptList,
  getListReportDeptListSuccess,
  getListReportDeptListFail,

  getListReportDeptDetail,
  getListReportDeptDetailSuccess,
  getListReportDeptDetailFail,

  getListReportOrderStatus,
  getListReportOrderStatusSuccess,
  getListReportOrderStatusFail,

  getListHistoryOrder,
  getListHistoryOrderSuccess,
  getListHistoryOrderFail,

  getListBillOfLadingCode,
  getListBillOfLadingCodeSuccess,
  getListBillOfLadingCodeFail,

} = orderDucks.actions

export default orderDucks.reducer
