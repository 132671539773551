import ApisService from './index'
import { IWarehouseAdd, IWarehouseUpdate } from '../interfaces/warehouseInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createWarehouse(data: IWarehouseAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/kho/create-kho', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateWarehouse(data: IWarehouseUpdate) {
  try {
    return ApisService.put('/muahang-backend/v1/kho/' + data.id + '/update-kho', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailWarehouse(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/kho/' + id + '/get-chitiet-kho')
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function getListWarehouse(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/kho/filter-kho', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteWarehouse(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/kho/' + id + '/delete-kho')
  } catch (err) {
    handleError(err)
    throw err
  }
}
