import ApisService from './index'
import { IRateAdd, IRateUpdate } from '../interfaces/rateInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'


export function createRate(data: IRateAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/tygia/create-tygia', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function createRateMulti(dataArr: Array<IRateAdd>) {
  try {
    return ApisService.post('/muahang-backend/v1/tygia/bulk-create-tygia', dataArr)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateRate(data: IRateUpdate) {
  try {
    return ApisService.put('/muahang-backend/v1/tygia/' + data.id + '/update-tygia', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getListRate(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/tygia/filter-tygia', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailRate(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/tygia/' + id + '/get-chitiet-tygia')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteRate(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/tygia/' + id + '/delete-tygia')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteMultiRate(idArr: Array<string>) {
  try {
    return ApisService.delete('/muahang-backend/v1/tygia/bulk-delete-tygia', { data: idArr })
  } catch (err) {
    handleError(err)
    throw err
  }
}



export function getListForm(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/tygia/get-all-cauhinh-tygia', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}