import { call, put, takeEvery } from 'redux-saga/effects'
import * as requestShareActions from '../ducks/requestShareDucks'
import * as requestShareApi from '../../api/requestShareApi'

interface FetchRequestShareListSaga {
  type: string
  payload: string
}

interface FetchRequestShareDetailSaga {
  type: string
  payload: string
}

function* fetchListRequestShare({ payload: queryString }: FetchRequestShareListSaga) {
  try {
    const res: ReturnType<typeof requestShareApi.getListRequestShare> = yield call(requestShareApi.getListRequestShare, queryString)
    yield put(requestShareActions.getListRequestShareSuccess(res))
  } catch (error) {
    yield put(requestShareActions.getListRequestShareFail(error))
  }
}

function* fetchDetailRequestShare({ payload: id }: FetchRequestShareDetailSaga) {
  try {
    const res: ReturnType<typeof requestShareApi.getDetailRequestShare> = yield call(requestShareApi.getDetailRequestShare, id)
    yield put(requestShareActions.getDetailRequestShareSuccess(res))
  } catch (error) {
    yield put(requestShareActions.getDetailRequestShareFail(error))
  }
}

export default function* watchRequestShare() {
  yield takeEvery(requestShareActions.getListRequestShare.toString(), fetchListRequestShare)
  yield takeEvery(requestShareActions.getDetailRequestShare.toString(), fetchDetailRequestShare)
}
