import { all } from 'redux-saga/effects'
import settingSaga from './settingSaga'
import userSaga from './userSaga'
import warehouseSaga from './warehouseSaga'
import supplierSaga from './supplierSaga'
import rateSaga from './rateSaga'
import transportationSaga from './transportationSaga'
import productSaga from './productSaga'
import purchasesRequestSaga from './purchasesRequestSaga'
import requestShareSaga from './requestShareSaga'
import orderSaga from './orderSaga'
import notificationSaga from './notificationSaga'

import categorieSaga from './categorieSaga'

import containerSaga from './containerSaga'
import requestPaymentSaga from './requestPaymentSaga'

import transProviderSaga from './transProviderSaga'
import taxProviderSaga from './taxProviderSaga'
import currencyProviderSaga from './currencyProviderSaga'
import productGroupSaga from './productGroupSaga'





export default function* rootSaga() {
  yield all([
    settingSaga(),
    userSaga(),
    warehouseSaga(),
    supplierSaga(),
    rateSaga(),
    transportationSaga(),
    productSaga(),
    purchasesRequestSaga(),
    requestShareSaga(),
    orderSaga(),
    notificationSaga(),

    categorieSaga(),
    containerSaga(),
    requestPaymentSaga(),

    transProviderSaga(),
    taxProviderSaga(),
    currencyProviderSaga(),
    productGroupSaga(),


  ])
}
