import ApisService from './index'
import {
  IPurchasesRequestAdd,
  IPurchasesRequestImport,
  IPurchasesRequestUpdate,
} from '../interfaces/purchasesRequestInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createPurchasesRequest(data: IPurchasesRequestAdd) {
  const bodyData = { ...data, ngayYeuCauNhanHangKhoVN: parseInt(data.ngayYeuCauNhanHangKhoVN) }

  try {
    return ApisService.post('/muahang-backend/v1/yeucaumua/create-yeucaumua', bodyData)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updatePurchasesRequest(data: IPurchasesRequestUpdate) {
  const bodyData = { ...data, ngayYeuCauNhanHangKhoVN: parseInt(data.ngayYeuCauNhanHangKhoVN) }
  try {
    return ApisService.put('/muahang-backend/v1/yeucaumua/' + data.id + '/update-yeucaumua', bodyData)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailPurchasesRequest(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/yeucaumua/' + id + '/get-chitiet-yeucaumua')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getListPurchasesRequest(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/yeucaumua/filter-yeucaumua', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deletePurchasesRequest(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/yeucaumua/' + id + '/delete-yeucaumua')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteMultIPurchasesRequest(idArr: Array<string>) {
  try {
    return ApisService.delete('/muahang-backend/v1/yeucaumua/bulk-delete-yeucaumua', { data: idArr })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function importPurchasesRequest(data: Array<IPurchasesRequestImport>) {
  try {
    return ApisService.post('/muahang-backend/v1/yeucaumua/import-yeucaumua', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function confirmPurchasesRequest(id: string) {
  try {
    return ApisService.put('/muahang-backend/v1/yeucaumua/' + id + '/dongy-baogia')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function rejectAllPurchasesRequest(id: string) {
  try {
    return ApisService.put('/muahang-backend/v1/yeucaumua/' + id + '/tuchoi-tatca-baogia')
  } catch (err) {
    handleError(err)
    throw err
  }
}
