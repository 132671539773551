import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface RequestPaymentState {
  requestPaymentList?: any
  requestPaymentDetail?: any
  requestPayment?: {
    id?: string
    donmua: any
    donMuaId: string
    ghiChu: string
    soTien?: number
    uuTien?: number
    isOpenModal: boolean
  }

}

const initialState: RequestPaymentState = {
  requestPaymentList: {},
  requestPaymentDetail: {},
  requestPayment: {
    id: "",
    donmua: {},
    donMuaId: "",
    ghiChu: "",
    soTien: 0,
    uuTien: 1,
    isOpenModal: false
  }


}

export const requestPaymentDucks = createSlice({
  name: 'requestPayment',
  initialState,
  reducers: {

    getListRequestPayment: (state: RootState, action: PayloadAction<any>) => {
      state.requestPaymentList = { loading: true, queryStr: action.payload }
    },
    getListRequestPaymentSuccess: (state, action: PayloadAction<any>) => {
      state.requestPaymentList = { loading: false, ...action.payload }
    },
    getListRequestPaymentFail: (state, action: PayloadAction<any>) => {
      state.requestPaymentList = { loading: false, ...action.payload }
    },

    getDetailRequestPayment: (state: RootState, action: PayloadAction<any>) => {
      state.requestPaymentDetail = { loading: true, id: action.payload }
    },
    getDetailRequestPaymentSuccess: (state, action: PayloadAction<any>) => {
      state.requestPaymentDetail = { loading: false, ...action.payload }
    },
    getDetailRequestPaymentFail: (state, action: PayloadAction<any>) => {
      state.requestPaymentDetail = { loading: false, ...action.payload }
    },

    setRequestPayment: (state, action: PayloadAction<any>) => {
      state.requestPayment = action.payload
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListRequestPayment,
  getListRequestPaymentSuccess,
  getListRequestPaymentFail,
  getDetailRequestPayment,
  getDetailRequestPaymentSuccess,
  getDetailRequestPaymentFail,
  setRequestPayment,
  
} = requestPaymentDucks.actions

export default requestPaymentDucks.reducer
