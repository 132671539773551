import { combineReducers } from 'redux'
import commonDucks from './commonDucks'
import settingDucks from './settingDucks'
import userDucks from './userDucks'
import warehouseDucks from './warehouseDucks'
import supplierDucks from './supplierDucks'
import rateDucks from './rateDucks'
import transportationDucks from './transportationDucks'
import productDucks from './productDucks'
import poupDucks from './poupDucks'
import purchasesRequestDucks from './purchasesRequestDucks'
import requestShareDucks from './requestShareDucks'
import orderDucks from './orderDucks'
import notificationDucks from './notificationDucks'

import categorieDucks from './categorieDucks'
import containerDucks from './containerDucks'
import requestPaymentDucks from './requestPaymentDucks'

import currencyProviderDucks from './currencyProviderDucks'
import transProviderDucks from './transProviderDucks'
import taxProviderDucks from './taxProviderDucks'
import productGroupDucks from './productGroupDucks'





const rootReducer = combineReducers({
  common: commonDucks,
  setting: settingDucks,
  user: userDucks,
  warehouse: warehouseDucks,
  supplier: supplierDucks,
  rate: rateDucks,
  transportation: transportationDucks,
  product: productDucks,
  poup: poupDucks,
  purchasesRequest: purchasesRequestDucks,
  requestShare: requestShareDucks,
  order: orderDucks,
  notification: notificationDucks,
  categorie: categorieDucks,
  container: containerDucks,
  requestPayment: requestPaymentDucks,

  currencyProvider: currencyProviderDucks,
  transProvider: transProviderDucks,
  taxProvider: taxProviderDucks,
  productGroup: productGroupDucks,


})

export default rootReducer
