import axios from 'axios'
import Cookies from 'universal-cookie'
import { logOut } from '../utils/userUtils'

const cookies = new Cookies()

const ApisService = axios.create({
  baseURL: process.env.REACT_APP_APIS_SERVICE_URL,
  responseType: 'json',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true'
  },
})

ApisService.defaults.timeout = 60000
ApisService.defaults.headers.post['Content-Type'] = 'application/json'

ApisService.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = cookies.get('access_token')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

ApisService.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data
  },
  function (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      console.log('401 || 403')
      // alert('saklfmkasdmkasmd')
      logOut()
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error.response.data)
  }
)

export default ApisService
