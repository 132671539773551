import ApisService from './index'
import { IContainerAdd, IContainerImport } from '../interfaces/containerInterfaces'
import { IOrderPurchasesAttach } from '../interfaces/orderInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'


export function createContainer(data: IContainerAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/hinhthucvanchuyen/create-container-gom', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateContainer(data: IContainerAdd) {
  try {
    return ApisService.put('/muahang-backend/v1/hinhthucvanchuyen/' + data.id + '/update-container-gom', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}



// cập hật tình trạng đơn hàng
export function updateOrderContainerAttach(thongTinThems: IOrderPurchasesAttach[]) {
  try {
    return ApisService.put('/muahang-backend/v1/donmua/update-donmua-thongtinthem', {thongTinThems: thongTinThems})
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function getListContainer(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/hinhthucvanchuyen/filter-container-gom', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailContainer(maContainer: string) {
  try {
    return ApisService.get('/muahang-backend/v1/hinhthucvanchuyen/' + maContainer + '/get-chitiet-container-gom')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteContainer(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/hinhthucvanchuyen/' + id + '/delete-container-gom')
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function deleteMultIContainer(idArr: Array<string>) {
  try {
    return ApisService.delete('/muahang-backend/v1/hinhthucvanchuyen/bulk-delete-container-gom', { data: idArr })
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function importContainer(data: Array<IContainerImport>) {
  try {
    return ApisService.post('/muahang-backend/v1/hinhthucvanchuyen/import-container-gom', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getListRequestCollect(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/donmua/get-muchang-chuagom-container', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

