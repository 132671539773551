import ApisService from './index'
import ApisService3 from './index3'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'


//  đơn vị tính giá vốn
export function getListUnit(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/sanpham/get-all-donvitinh', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}


//  đơn vị kahi báo
export function getListUnitReport(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/sanpham/get-all-donvikhaibao', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

//  đặc thù hàng
export function getListCategorie(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/sanpham/get-all-dacthuhang', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}


//  đặc thù hàng
export function getListWareHouse(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/yeucaumua/get-all-kho', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function uploadImage(image: any) {
  let formData = new FormData();    //formdata object

  formData.append('image', image);   //append the values with key, value pair
  try {
    return ApisService3.post('/muahang-backend/v1/common/upload-image', formData)
  } catch (err) {
    handleError(err)
    throw err
  }
}




