import { call, put, takeEvery } from 'redux-saga/effects'
import * as currencyProviderActions from '../ducks/currencyProviderDucks'
import * as currencyProviderApi from '../../api/currencyProviderApi'

interface FetchCurrencyProviderListSaga {
  type: string
  payload: string
}

interface FetchCurrencyProviderDetailSaga {
  type: string
  payload: string
}

function* fetchListCurrencyProvider({ payload: queryString }: FetchCurrencyProviderListSaga) {
  try {
    const res: ReturnType<typeof currencyProviderApi.getListCurrencyProvider> = yield call(currencyProviderApi.getListCurrencyProvider, queryString)
    yield put(currencyProviderActions.getListCurrencyProviderSuccess(res))
  } catch (error) {
    yield put(currencyProviderActions.getListCurrencyProviderFail(error))
  }
}

function* fetchDetailCurrencyProvider({ payload: id }: FetchCurrencyProviderDetailSaga) {
  try {
    const res: ReturnType<typeof currencyProviderApi.getDetailCurrencyProvider> = yield call(currencyProviderApi.getDetailCurrencyProvider, id)
    yield put(currencyProviderActions.getDetailCurrencyProviderSuccess(res))
  } catch (error) {
    yield put(currencyProviderActions.getDetailCurrencyProviderFail(error))
  }
}


export default function* watchCurrencyProvider() {
  yield takeEvery(currencyProviderActions.getListCurrencyProvider.toString(), fetchListCurrencyProvider)
  yield takeEvery(currencyProviderActions.getDetailCurrencyProvider.toString(), fetchDetailCurrencyProvider)
}
