import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface SettingState {
  unitList?: any
  unitReportList?: any
  categorieList?: any
  sourceWarehouseList?: any
  destinWarehouseList?: any
}

const initialState: SettingState = {
  unitList: {},
  unitReportList: {},
  categorieList: {},
  sourceWarehouseList: {},
  destinWarehouseList: {},
}

export const settingDucks = createSlice({
  name: 'setting',
  initialState,
  reducers: {
   
    getListUnit: (state: RootState) => {
      state.unitList = { loading: true}
    },
    getListUnitSuccess: (state, action: PayloadAction<any>) => {
      state.unitList = { loading: false, ...action.payload }
    },
    getListUnitFail: (state, action: PayloadAction<any>) => {
      state.unitList = { loading: false, ...action.payload }
    },

    getListUnitReport: (state: RootState) => {
      state.unitReportList = { loading: true}
    },
    getListUnitReportSuccess: (state, action: PayloadAction<any>) => {
      state.unitReportList = { loading: false, ...action.payload }
    },
    getListUnitReportFail: (state, action: PayloadAction<any>) => {
      state.unitReportList = { loading: false, ...action.payload }
    },
   
    getListCategorie: (state: RootState) => {
      state.categorieList = { loading: true}
    },
    getListCategorieSuccess: (state, action: PayloadAction<any>) => {
      state.categorieList = { loading: false, ...action.payload }
    },
    getListCategorieFail: (state, action: PayloadAction<any>) => {
      state.categorieList = { loading: false, ...action.payload }
    },

    getListSourceWarehouse: (state: RootState) => {
      state.sourceWarehouseList = { loading: true}
    },
    getListSourceWarehouseSuccess: (state, action: PayloadAction<any>) => {
      state.sourceWarehouseList = { loading: false, ...action.payload }
    },
    getListSourceWarehouseFail: (state, action: PayloadAction<any>) => {
      state.sourceWarehouseList = { loading: false, ...action.payload }
    },

    getListDestinWarehouse: (state: RootState) => {
      state.destinWarehouseList = { loading: true}
    },
    getListDestinWarehouseSuccess: (state, action: PayloadAction<any>) => {
      state.destinWarehouseList = { loading: false, ...action.payload }
    },
    getListDestinWarehouseFail: (state, action: PayloadAction<any>) => {
      state.destinWarehouseList = { loading: false, ...action.payload }
    },
   
  },
})

// Action creators are generated for each case reducer function
export const {
  getListUnit,
  getListUnitSuccess,
  getListUnitFail,

  getListUnitReport,
  getListUnitReportSuccess,
  getListUnitReportFail,

  getListCategorie,
  getListCategorieSuccess,
  getListCategorieFail,

  getListSourceWarehouse,
  getListSourceWarehouseSuccess,
  getListSourceWarehouseFail,
  
  getListDestinWarehouse,
  getListDestinWarehouseSuccess,
  getListDestinWarehouseFail,
  
} = settingDucks.actions

export default settingDucks.reducer
