import React, { ReactElement } from 'react'
// import { Layout } from 'antd'
//
// const { Content } = Layout

interface Props {
  children: ReactElement
}

const NothingLayout: React.FC<Props> = ({ children }) => {
  return children
}

export default NothingLayout
