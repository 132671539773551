import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface ProductGroupState {
  productGroupList?: any
  productGroupDetail?: any
}

const initialState: ProductGroupState = {
  productGroupList: {},
  productGroupDetail: {},
}

export const productGroupDucks = createSlice({
  name: 'productGroup',
  initialState,
  reducers: {
   
    getListProductGroup: (state: RootState, action: PayloadAction<any>) => {
      state.productGroupList = { loading: true, queryStr: action.payload }
    },
    getListProductGroupSuccess: (state, action: PayloadAction<any>) => {
      state.productGroupList = { loading: false, ...action.payload }
    },
    getListProductGroupFail: (state, action: PayloadAction<any>) => {
      state.productGroupList = { loading: false, ...action.payload }
    },
    
    getDetailProductGroup: (state: RootState, action: PayloadAction<any>) => {
      state.productGroupDetail = { loading: true, id: action.payload }
    },
    getDetailProductGroupSuccess: (state, action: PayloadAction<any>) => {
      state.productGroupDetail = { loading: false, ...action.payload }
    },
    getDetailProductGroupFail: (state, action: PayloadAction<any>) => {
      state.productGroupDetail = { loading: false, ...action.payload }
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListProductGroup,
  getListProductGroupSuccess,
  getListProductGroupFail,
  getDetailProductGroup,
  getDetailProductGroupSuccess,
  getDetailProductGroupFail,
} = productGroupDucks.actions

export default productGroupDucks.reducer
