import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface CategorieState {
  categorieList?: any
  categorieDetail?: any
}

const initialState: CategorieState = {
  categorieList: {},
  categorieDetail: {},
}

export const categorieDucks = createSlice({
  name: 'categorie',
  initialState,
  reducers: {
   
    getListCategorie: (state: RootState, action: PayloadAction<any>) => {
      state.categorieList = { loading: true, queryStr: action.payload }
    },
    getListCategorieSuccess: (state, action: PayloadAction<any>) => {
      state.categorieList = { loading: false, ...action.payload }
    },
    getListCategorieFail: (state, action: PayloadAction<any>) => {
      state.categorieList = { loading: false, ...action.payload }
    },
    
    getDetailCategorie: (state: RootState, action: PayloadAction<any>) => {
      state.categorieDetail = { loading: true, id: action.payload }
    },
    getDetailCategorieSuccess: (state, action: PayloadAction<any>) => {
      state.categorieDetail = { loading: false, ...action.payload }
    },
    getDetailCategorieFail: (state, action: PayloadAction<any>) => {
      state.categorieDetail = { loading: false, ...action.payload }
    },
    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListCategorie,
  getListCategorieSuccess,
  getListCategorieFail,
  getDetailCategorie,
  getDetailCategorieSuccess,
  getDetailCategorieFail,
} = categorieDucks.actions

export default categorieDucks.reducer
