import { call, put, takeEvery } from 'redux-saga/effects'
import * as supplierActions from '../ducks/supplierDucks'
import * as supperlierApi from '../../api/supplierApi'

interface FetchSupplierListSaga {
  type: string
  payload: string
}

interface FetchSupplierDetailSaga {
  type: string
  payload: string
}

function* fetchListSupplier({ payload: queryString }: FetchSupplierListSaga) {
  try {
    const res: ReturnType<typeof supperlierApi.getListSupplier> = yield call(supperlierApi.getListSupplier, queryString)
    yield put(supplierActions.getListSupplierSuccess(res))
  } catch (error) {
    yield put(supplierActions.getListSupplierFail(error))
  }
}

function* fetchDetailSupplier({ payload: id }: FetchSupplierDetailSaga) {
  try {
    const res: ReturnType<typeof supperlierApi.getDetailSupplier> = yield call(supperlierApi.getDetailSupplier, id)
    yield put(supplierActions.getDetailSupplierSuccess(res))
  } catch (error) {
    yield put(supplierActions.getDetailSupplierFail(error))
  }
}


export default function* watchSupplier() {
  yield takeEvery(supplierActions.getListSupplier.toString(), fetchListSupplier)
  yield takeEvery(supplierActions.getDetailSupplier.toString(), fetchDetailSupplier)
}
