import ApisService from './index'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function getListNotification(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/common/my-notifications', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function getListAllNotification(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/common/my-notifications', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateNotification(id: string) {
  try {
    return ApisService.put('/muahang-backend/v1/common/' + id + '/read-notification')
  } catch (err) {
    handleError(err)
    throw err
  }
}

