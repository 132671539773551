import ApisService from './index'
import { IUserUpdate, IUserAdd , IUserUpdateRole} from '../interfaces/userInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function register(data: IUserAdd) {
  let bodyData: any = { ...data }
  const phoneNumber = data.phoneNumber || ""

  delete bodyData.phoneNumber
  if (phoneNumber.trim() != "") {
    bodyData.phone = { 
      phoneNumber: phoneNumber, 
      countryAccessCode: '84', }
  }
  if (!bodyData.birthDate) {
    delete bodyData.birthDate
  }
  return ApisService.post('/muahang-backend/v1/user/register', bodyData)
}

export async function login(username: string, password: string) {
  return await ApisService.post('/muahang-backend/v1/user/login', {
    username,
    password,
  })
}

export function updateProfile(data: IUserUpdate) {
  let bodyData: any = { ...data }
  const phoneNumber = data.phoneNumber || ""

  delete bodyData.phoneNumber
  if (phoneNumber.trim() != "") {
    bodyData.phone = { 
      phoneNumber: phoneNumber, 
      countryAccessCode: '84', }
  }
  if (!bodyData.birthDate) {
    delete bodyData.birthDate
  }
  return ApisService.put('/muahang-backend/v1/user/update-user-profile', bodyData)
}


export function updateUser(data: IUserUpdate) {
  let bodyData: any = { ...data }
  const phoneNumber = data.phoneNumber || ""

  delete bodyData.phoneNumber
  if (phoneNumber.trim() != "") {
    bodyData.phone = { 
      phoneNumber: phoneNumber, 
      countryAccessCode: '84', }
  }
  if (!bodyData.birthDate) {
    delete bodyData.birthDate
  }
  return ApisService.put('/muahang-backend/v1/user/update-user-profile/' + data.id, bodyData)
}


export function getUserInfo() {
  return ApisService.get('/muahang-backend/v1/user/get-user-info')
}

export function getDetailUser(id: string) {
  return ApisService.get('/muahang-backend/v1/user/get-user-info/' + id)
}

export function getListUser(querySt: any) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/user/get-user-list', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}



export function changePassword({
  oldPassword,
  newPassword,
  newPasswordRetype,
}: {
  oldPassword: string
  newPassword: string
  newPasswordRetype: string
}) {
  return ApisService.post('/muahang-backend/v1/user/update-user-profile', {
    oldPassword,
    newPassword,
    newPasswordRetype,
  })
}


export function getListRole(query: any) {
  return ApisService.get('/muahang-backend/v1/user/get-roles', {
    params: query,
  })
}

export function getListProject() {
  return ApisService.get('/muahang-backend/v1/user/get-my-duan-list')
}

export function changeProject(projectId: number) {
  return ApisService.put('/muahang-backend/v1/user/' + projectId + '/doi-duan')
}


export function updateRole(data: IUserUpdateRole) {
  const bodyData = {...data, role: data.role != "" ? data.role : null}
  return ApisService.put('/muahang-backend/v1/user/' + data.projectId + '/update-role-duan', bodyData)
}