import { lazy } from 'react'
import BasicLayout from '../components/layout/BasicLayout'
import NothingLayout from '../components/layout/NothingLayout'
// import AdminLayout from '../components/layout/AdminLayout'
import routerConstants from '../constants/routerConstants'
// import routes_v3 from './index_v3'

import { role } from '../constants/roleConstants'
import { getUserRole } from '../utils/userUtils'

const user_role = getUserRole()

const routePublic: any[] = [
  { path: '/login', name: 'Login', exact: true, layout: NothingLayout, component: lazy(() => import('../components/login/Login')), },
  {
    path: '', name: '404', exact: true, layout: BasicLayout, component: lazy(() => import('../components/404/NotFound')),
  },

]

const routeUser: any[] = [
  { path: routerConstants.user.list, name: 'userList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/user/UserList')), },
  { path: routerConstants.user.add, name: 'userAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/user/UserAdd')), },
  { path: routerConstants.user.detail, name: 'userDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/user/UserDetail')), },
  { path: routerConstants.user.edit, name: 'userEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/user/UserUpdate')), },
]

const routeWarehouse: any[] = [
  { path: routerConstants.warehouse.list, name: 'warehouseList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/warehouse/WarehouseList')), },
  { path: routerConstants.warehouse.add, name: 'warehouseAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/warehouse/WarehouseAdd')), },
  { path: routerConstants.warehouse.detail, name: 'warehouseDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/warehouse/WarehouseDetail')), },
  { path: routerConstants.warehouse.edit, name: 'warehouseEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/warehouse/WarehouseUpdate')), },
]

const routeTransProvider: any[] = [
  { path: routerConstants.transProvider.list, name: 'transProviderList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/transProvider/TransProviderList')), },
  { path: routerConstants.transProvider.add, name: 'transProviderAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/transProvider/TransProviderAdd')), },
  { path: routerConstants.transProvider.edit, name: 'transProviderEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/transProvider/TransProviderUpdate')), },
]

const routeCurrencyProvider: any[] = [
  { path: routerConstants.currencyProvider.list, name: 'currencyProviderList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/currencyProvider/CurrencyProviderList')), },
  { path: routerConstants.currencyProvider.add, name: 'currencyProviderAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/currencyProvider/CurrencyProviderAdd')), },
  { path: routerConstants.currencyProvider.edit, name: 'currencyProviderEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/currencyProvider/CurrencyProviderUpdate')), },
]

const routeTaxProvider: any[] = [
  { path: routerConstants.taxProvider.list, name: 'taxProviderList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/taxProvider/TaxProviderList')), },
  { path: routerConstants.taxProvider.add, name: 'taxProviderAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/taxProvider/TaxProviderAdd')), },
  { path: routerConstants.taxProvider.edit, name: 'taxProviderEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/taxProvider/TaxProviderUpdate')), },
]

const routeSupplier: any[] = [
  { path: routerConstants.supplier.list, name: 'supplierList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/supplier/SupplierList')), },
  { path: routerConstants.supplier.detail, name: 'supplierDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/supplier/SupplierDetail')), },
  ...(((user_role == role.SUPPER_ADMIN) || (user_role == role.ADMIN) || (user_role == role.ORDER_MANAGER) || (user_role == role.ORDER)) ?
    [
      { path: routerConstants.supplier.add, name: 'supplierAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/supplier/SupplierAdd')), },
      { path: routerConstants.supplier.edit, name: 'supplierEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/supplier/SupplierUpdate')), },
      { path: routerConstants.supplier.import, name: 'supplierImport', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/supplier/SupplierImport')), },
    ] : []),
]

const routeRate: any[] = [
  { path: routerConstants.rate.list, name: 'rateList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/rate/RateList')), },
  { path: routerConstants.rate.add, name: 'rateAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/rate/RateAdd')), },
  { path: routerConstants.rate.detail, name: 'rateDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/rate/RateDetail')), },
  { path: routerConstants.rate.edit, name: 'rateEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/rate/RateUpdate')), },
]

const routeTransportation: any[] = [
  { path: routerConstants.transportation.list, name: 'transportationList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/transportation/TransportationList')), },
  { path: routerConstants.transportation.add, name: 'transportationAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/transportation/TransportationAdd')), },
  { path: routerConstants.transportation.detail, name: 'transportationDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/transportation/TransportationDetail')), },
  { path: routerConstants.transportation.edit, name: 'transportationEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/transportation/TransportationUpdate')), },
  { path: routerConstants.transportation.import, name: 'transportationImport', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/transportation/TransportationImport')), },
]

const routeContainer: any[] = [
  { path: routerConstants.container.list, name: 'containerList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/container/ContainerList')), },
  { path: routerConstants.container.detail, name: 'containerDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/container/ContainerDetail')), },
  ...(((user_role == role.SUPPER_ADMIN) || (user_role == role.ADMIN) || (user_role == role.ORDER_MANAGER) || (user_role == role.ORDER)) ?
    [
      { path: routerConstants.container.add, name: 'containerAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/container/ContainerAdd')), },
      { path: routerConstants.container.edit, name: 'containerEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/container/ContainerUpdate')), },
      { path: routerConstants.container.updateInfo, name: 'containerUpdateInfo', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/container/ContainerUpdateInfo')), },
    ] : []),
]

const routeCategorie: any[] = [
  { path: routerConstants.categorie.list, name: 'categorieList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/categorie/CategorieList')), },
  { path: routerConstants.categorie.add, name: 'categorieAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/categorie/CategorieAdd')), },
  { path: routerConstants.categorie.import, name: 'categorieImport', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/categorie/CategorieImport')), },
  { path: routerConstants.categorie.detail, name: 'categorieDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/categorie/CategorieDetail')), },
  { path: routerConstants.categorie.edit, name: 'categorieEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/categorie/CategorieUpdate')), },
]

const routeProductGroup: any[] = [
  { path: routerConstants.productGroup.list, name: 'productGroupList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/productGroup/ProductGroupList')), },
  { path: routerConstants.productGroup.add, name: 'productGroupAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/productGroup/ProductGroupAdd')), },
  { path: routerConstants.productGroup.detail, name: 'productGroupDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/productGroup/ProductGroupDetail')), },
  { path: routerConstants.productGroup.edit, name: 'productGroupEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/productGroup/ProductGroupUpdate')), },
]

const routeProduct: any[] = [
  { path: routerConstants.product.list, name: 'productList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/product/ProductList')), },
  { path: routerConstants.product.listProductSupplier, name: 'productList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/product/ProductListSupplier')), },
  { path: routerConstants.product.listProductWarehouse, name: 'productList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/product/ProductListWarehouse')), },
  { path: routerConstants.product.listAll, name: 'productList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/product/ProductListAll')), },

  { path: routerConstants.product.add, name: 'productAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/product/ProductAdd')), },
  { path: routerConstants.product.import, name: 'productImport', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/product/ProductImport')), },
  { path: routerConstants.product.detail, name: 'productDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/product/ProductDetail')), },
  { path: routerConstants.product.edit, name: 'productEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/admin/product/ProductUpdate')), },
]

const routePurchasesRequest: any[] = [
  { path: routerConstants.purchasesRequest.list, name: 'purchasesRequestList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/purchasesRequest/PurchasesRequestList')), },
  { path: routerConstants.purchasesRequest.detail, name: 'purchasesRequestDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/order/purchasesRequest/PurchasesRequestDetail')), },

  ...(((user_role == role.SUPPER_ADMIN) || (user_role == role.ADMIN) || (user_role == role.MANAGER) || (user_role == role.SALE_MANAGER)) ?
    [
      { path: routerConstants.purchasesRequest.add, name: 'purchasesRequestAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/purchasesRequest/PurchasesRequestAdd')), },
      { path: routerConstants.purchasesRequest.import, name: 'purchasesRequestImport', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/purchasesRequest/PurchasesRequestImport')), },
      { path: routerConstants.purchasesRequest.edit, name: 'purchasesRequestEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/order/purchasesRequest/PurchasesRequestUpdate')), },
    ] : []),
]

const routeRequestShare: any[] = [
  { path: routerConstants.requestShare.list, name: 'requestShareList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/requestShare/RequestShareList')), },
]

const routePricing: any[] = [
  { path: routerConstants.pricing.list, name: 'pricingList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/pricing/PricingList')), },
  { path: routerConstants.pricing.add, name: 'pricingAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/pricing/PricingAdd')), },
  { path: routerConstants.pricing.detail, name: 'pricingDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/order/pricing/PricingDetail')), },
  { path: routerConstants.pricing.edit, name: 'pricingEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/order/pricing/PricingUpdate')), },
]

const routePricingConfirm: any[] = [
  { path: routerConstants.pricingConfirm.list, name: 'pricingConfirmList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/pricingConfirm/PricingConfirmList')), },
  { path: routerConstants.pricingConfirm.detail, name: 'pricingConfirmDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/order/pricingConfirm/PricingConfirmDetail')), },
]

const routeRequestPayment: any[] = [
  { path: routerConstants.requestPayment.add, name: 'requestPaymentAdd', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/requestPayment/RequestPaymentOrderList')), },
  { path: routerConstants.requestPayment.list, name: 'requestPaymentList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/requestPayment/RequestPaymentList')), },
]

const routePaymentOrder: any[] = [
  { path: routerConstants.paymentOrder.list, name: 'paymentOrderList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/paymentOrder/PaymentOrderList')), },
  ...(((user_role == role.SUPPER_ADMIN) || (user_role == role.ADMIN) || (user_role == role.ACCOUNTANT)) ?
    [
      { path: routerConstants.paymentOrder.edit, name: 'paymentOrderEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/order/paymentOrder/PaymentOrderUpdate')), },
    ] : []),
]

const routeOrder: any[] = [
  { path: routerConstants.order.list, name: 'OrderList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/order/OrderList')), },
  { path: routerConstants.order.detail, name: 'OrderDetail', exact: false, layout: BasicLayout, component: lazy(() => import('../components/order/order/OrderDetail')), },

  ...(((user_role == role.SUPPER_ADMIN) || (user_role == role.ADMIN) || (user_role == role.ORDER) || (user_role == role.ORDER_MANAGER) || (user_role == role.ACCOUNTANT)) ?
    [
      { path: routerConstants.order.edit, name: 'OrderEdit', exact: false, layout: BasicLayout, component: lazy(() => import('../components/order/order/OrderUpdate')), },
      { path: routerConstants.order.updateReality, name: 'OrderUpdateReality', exact: false, layout: BasicLayout, component: lazy(() => import('../components/order/order/OrderUpdateReality')), },
    ] : []),
]

const routeReport: any[] = [
  { path: routerConstants.report.reportCapitalPrice, name: 'reportCapitalPrice', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/report/ReportCapitalPriceList')), },
  { path: routerConstants.report.reportDeptList, name: 'reportDeptList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/report/ReportDeptList')), },
  { path: routerConstants.report.reportDeptDetail, name: 'reportDeptDetail', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/report/ReportDeptDetail')), },
  { path: routerConstants.report.reportOrderStatus, name: 'reportOrderStatus', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/report/ReportOrderStatusList')), },
]

const routeNotification: any[] = [
  { path: routerConstants.notification.list, name: 'notification', exact: true, layout: BasicLayout, component: lazy(() => import('../components/admin/notification/NotificationList')), },
]

const routeHistory: any[] = [
  { path: routerConstants.historyOrder.list, name: 'historyOrder', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/historyOrder/HistoryOrderList')), },
]

const routeBillOfLadingCode: any[] = [
  { path: routerConstants.billOfLadingCode.list, name: 'billOfLadingCodeList', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/billOfLeadingCode/BillOfLadingCodeList')), },
  { path: routerConstants.billOfLadingCode.import, name: 'billOfLadingCodeImport', exact: true, layout: BasicLayout, component: lazy(() => import('../components/order/billOfLeadingCode/BillOfLadingCodeImport')), },
]

const routeCommon: any[] = [
  { path: '/', name: 'Home', exact: true, layout: BasicLayout, component: lazy(() => import('../components/home/Home')), },
  { path: '/test-123456', name: 'test', exact: true, layout: BasicLayout, component: lazy(() => import('../components/TEST')), },

]

const routeSupperAdminUser: any[] = [
  ...routeTransProvider, ...routeCurrencyProvider, ...routeTaxProvider, ...routeProductGroup,
  ...routeCommon,
  // source
  ...routeUser, ...routeWarehouse, ...routeSupplier, ...routeRate, ...routeTransportation, ...routeContainer, ...routeCategorie, ...routeProduct,
  // order
  ...routePurchasesRequest, ...routeRequestShare, ...routePricing, ...routePricingConfirm, ...routeRequestPayment, ...routePaymentOrder,
  ...routeOrder, ...routeReport, ...routeNotification, ...routeHistory, ...routeBillOfLadingCode
]

const routeAdminUser: any[] = [
  ...routeCommon,
  // source
  ...routeUser, ...routeWarehouse, ...routeSupplier, ...routeRate, ...routeTransportation, ...routeContainer, ...routeCategorie, ...routeProduct,
  // order
  ...routePurchasesRequest, ...routeRequestShare, ...routePricing, ...routePricingConfirm, ...routeRequestPayment, ...routePaymentOrder,
  ...routeOrder, ...routeReport, ...routeNotification, ...routeHistory, ...routeBillOfLadingCode
]



const routeSaleManagerUser: any[] = [
  ...routeCommon,
  // source
  ...routeCategorie, ...routeProduct,
  // order
  ...routePurchasesRequest, ...routeRequestShare, ...routePricingConfirm,
  ...routeNotification
]
const routeSaleUser: any[] = [
  ...routeCommon,
  // source
  ...routeCategorie, ...routeProduct,
  // order
  ...routePurchasesRequest,
  ...routeNotification
]

const routeOrderManagerUser: any[] = [
  ...routeCommon,
  // source
  ...routeSupplier, ...routeRate, ...routeTransportation, ...routeContainer, ...routeCategorie, ...routeProduct,
  // order
  ...routePurchasesRequest, ...routeRequestShare, ...routePricing, ...routeRequestPayment, ...routePaymentOrder,
  ...routeOrder, ...routeReport, ...routeNotification, ...routeHistory, ...routeBillOfLadingCode
]
const routeOrderUser: any[] = [
  ...routeCommon,
  // source
  ...routeSupplier, ...routeRate, ...routeTransportation, ...routeContainer, ...routeCategorie, ...routeProduct,
  // order
  ...routePurchasesRequest, ...routeRequestShare, ...routePricing, ...routeRequestPayment, ...routePaymentOrder,
  ...routeOrder, ...routeReport, ...routeNotification, ...routeHistory, ...routeBillOfLadingCode
]

const routeAccountantUser: any[] = [
  ...routeCommon,
  // source
  ...routeSupplier, ...routeRate, ...routeTransportation, ...routeContainer, ...routeCategorie, ...routeProduct,
  // order
  ...routePricing, ...routeRequestPayment, ...routePaymentOrder,
  ...routeOrder, ...routeReport, ...routeNotification, ...routeHistory
]

let routes: any[] = []
switch (user_role) {
  case role.SUPPER_ADMIN:
    routes = routeSupperAdminUser
    break
  case role.ADMIN:
    routes = routeAdminUser
    break
  case role.MANAGER:
    routes = routeAdminUser
    break
  case role.SALE_MANAGER:
    routes = routeSaleManagerUser
    break
  case role.SALE:
    routes = routeSaleUser
    break
  case role.ORDER_MANAGER:
    routes = routeOrderManagerUser
    break
  case role.ORDER:
    routes = routeOrderUser
    break
  case role.ACCOUNTANT:
    routes = routeAccountantUser
    break
  default:
    break
}
routes = 
[
  // ...routes_v3,
  ...routes,
  ...routePublic,
]

export default routes
