import { call, put, takeEvery } from 'redux-saga/effects'
import * as transProviderActions from '../ducks/transProviderDucks'
import * as transProviderApi from '../../api/transProviderApi'

interface FetchTransProviderListSaga {
  type: string
  payload: string
}

interface FetchTransProviderDetailSaga {
  type: string
  payload: string
}

function* fetchListTransProvider({ payload: queryString }: FetchTransProviderListSaga) {
  try {
    const res: ReturnType<typeof transProviderApi.getListTransProvider> = yield call(transProviderApi.getListTransProvider, queryString)
    yield put(transProviderActions.getListTransProviderSuccess(res))
  } catch (error) {
    yield put(transProviderActions.getListTransProviderFail(error))
  }
}

function* fetchDetailTransProvider({ payload: id }: FetchTransProviderDetailSaga) {
  try {
    const res: ReturnType<typeof transProviderApi.getDetailTransProvider> = yield call(transProviderApi.getDetailTransProvider, id)
    yield put(transProviderActions.getDetailTransProviderSuccess(res))
  } catch (error) {
    yield put(transProviderActions.getDetailTransProviderFail(error))
  }
}


export default function* watchTransProvider() {
  yield takeEvery(transProviderActions.getListTransProvider.toString(), fetchListTransProvider)
  yield takeEvery(transProviderActions.getDetailTransProvider.toString(), fetchDetailTransProvider)
}
