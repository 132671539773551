import ApisService from './index'
import { ITransportationAdd, ITransportationUpdate, ITransportationImport } from '../interfaces/transportationInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'
import { CN } from '../constants/commonConstants'


export function createTransportation(data: ITransportationAdd) {

  const bodyData = {...data, ngayApDung: parseInt(data.ngayApDung), dacThuHangId: data.loai == CN ? null : data.dacThuHangId, donGiaKg: data.loai == CN ? null : data.donGiaKg}

  try {
    return ApisService.post('/muahang-backend/v1/hinhthucvanchuyen/create-hinhthucvanchuyen', bodyData)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateTransportation(data: ITransportationUpdate) {
  const bodyData = {...data, ngayApDung: parseInt(data.ngayApDung), dacThuHangId: data.loai == CN ? null : data.dacThuHangId}
  try {
    return ApisService.put('/muahang-backend/v1/hinhthucvanchuyen/' + data.id + '/update-hinhthucvanchuyen', bodyData)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getListTransportation(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/hinhthucvanchuyen/filter-hinhthucvanchuyen', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailTransportation(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/hinhthucvanchuyen/' + id + '/get-chitiet-hinhthucvanchuyen')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteTransportation(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/hinhthucvanchuyen/' + id + '/delete-hinhthucvanchuyen')
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function deleteMultiTransportation(idArr: Array<string>) {
  try {
    return ApisService.delete('/muahang-backend/v1/hinhthucvanchuyen/bulk-delete-hinhthucvanchuyen', { data: idArr })
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function importTransportation(data: Array<ITransportationImport>) {
  try {
    return ApisService.post('/muahang-backend/v1/hinhthucvanchuyen/import-hinhthucvanchuyen', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

// // Gom cont
// export function getListCont(querySt?: string) {
//   try {
//     const query = querySt ? queryString.parse(querySt) : {}
//     return ApisService.get('/muahang-backend/v1/hinhthucvanchuyen/filter-container-gom', {
//       params: query || {},
//     })
//   } catch (err) {
//     handleError(err)
//     throw err
//   }
// }

// export function getDetailCont(ma: string) {
//   try {
//     return ApisService.get('/muahang-backend/v1/hinhthucvanchuyen/' + ma + '/get-chitiet-container-gom')
//   } catch (err) {
//     handleError(err)
//     throw err
//   }
// }

// export function createCont(data: IContAdd) {
//   try {
//     return ApisService.post('/muahang-backend/v1/hinhthucvanchuyen/create-container-gom', data)
//   } catch (err) {
//     handleError(err)
//     throw err
//   }
// }

// export function updateCont(data: IContUpdate) {
//   try {
//     return ApisService.put('/muahang-backend/v1/hinhthucvanchuyen/' + data.id + '/update-container-gom', data)
//   } catch (err) {
//     handleError(err)
//     throw err
//   }
// }

// export function deleteCont(id: string) {
//   try {
//     return ApisService.delete('/muahang-backend/v1/hinhthucvanchuyen/' + id + '/delete-container-gom')
//   } catch (err) {
//     handleError(err)
//     throw err
//   }
// }

