import axios from 'axios'
import ApisService from './index'
import { handleError } from '../utils/commonUtils'

export function listDepartment(query?: any) {
  try {
    return ApisService.get('/muahang-backend/v1/common/departments', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function convertCurrency(q: string) {
  return axios
    .get('https://free.currconv.com/api/v7/convert', {
      params: {
        q: q,
        compact: 'ultra',
        apiKey: process.env.REACT_APP_CONVERT_CURRENCY_API_KEY,
      },
    })
    .then((res) => res.data)
}

export function getCurrentConfig() {
  try {
    return ApisService.get('/muahang-backend/v1/common/settings')
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateRateCnyToVnd(rate: number) {
  try {
    return ApisService.put('/muahang-backend/v1/common/config-rate-cny-to-vnd', {
      rateCnyVnd: rate,
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}
