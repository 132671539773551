import { call, put, takeEvery } from 'redux-saga/effects'
import * as categorieActions from '../ducks/categorieDucks'
import * as categorieApi from '../../api/categorieApi'

interface FetchCategorieListSaga {
  type: string
  payload: string
}

interface FetchCategorieDetailSaga {
  type: string
  payload: string
}

function* fetchListCategorie({ payload: queryString }: FetchCategorieListSaga) {
  try {
    const res: ReturnType<typeof categorieApi.getListCategorie> = yield call(categorieApi.getListCategorie, queryString)
    yield put(categorieActions.getListCategorieSuccess(res))
  } catch (error) {
    yield put(categorieActions.getListCategorieFail(error))
  }
}

function* fetchDetailCategorie({ payload: id }: FetchCategorieDetailSaga) {
  try {
    const res: ReturnType<typeof categorieApi.getDetailCategorie> = yield call(categorieApi.getDetailCategorie, id)
    yield put(categorieActions.getDetailCategorieSuccess(res))
  } catch (error) {
    yield put(categorieActions.getDetailCategorieFail(error))
  }
}


export default function* watchCategorie() {
  yield takeEvery(categorieActions.getListCategorie.toString(), fetchListCategorie)
  yield takeEvery(categorieActions.getDetailCategorie.toString(), fetchDetailCategorie)
}
