import ApisService from './index'
import { ITransProviderAdd, ITransProviderUpdate } from '../interfaces/transProviderInterfaces'
import { handleError } from '../utils/commonUtils'
import * as queryString from 'query-string'

export function createTransProvider(data: ITransProviderAdd) {
  try {
    return ApisService.post('/muahang-backend/v1/nhacungcap-vanchuyen/create-nhacungcap-vanchuyen', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function updateTransProvider(data: ITransProviderUpdate) {
  try {
    return ApisService.put('/muahang-backend/v1/nhacungcap-vanchuyen/' + data.id + '/update-nhacungcap-vanchuyen', data)
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function getDetailTransProvider(id: string) {
  try {
    return ApisService.get('/muahang-backend/v1/nhacungcap-vanchuyen/' + id + '/get-chitiet-nhacungcap-vanchuyen')
  } catch (err) {
    handleError(err)
    throw err
  }
}


export function getListTransProvider(querySt?: string) {
  try {
    const query = querySt ? queryString.parse(querySt) : {}
    return ApisService.get('/muahang-backend/v1/nhacungcap-vanchuyen/filter-nhacungcap-vanchuyen', {
      params: query || {},
    })
  } catch (err) {
    handleError(err)
    throw err
  }
}

export function deleteTransProvider(id: string) {
  try {
    return ApisService.delete('/muahang-backend/v1/nhacungcap-vanchuyen/' + id + '/delete-nhacungcap-vanchuyen')
  } catch (err) {
    handleError(err)
    throw err
  }
}
