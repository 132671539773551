import { call, put, takeEvery } from 'redux-saga/effects'
import * as purchasesRequestActions from '../ducks/purchasesRequestDucks'
import * as purchasesRequestApi from '../../api/purchasesRequestApi'

interface FetchPurchasesRequestListSaga {
  type: string
  payload: string
}

interface FetchPurchasesRequestDetailSaga {
  type: string
  payload: string
}

function* fetchListPurchasesRequest({ payload: queryString }: FetchPurchasesRequestListSaga) {
  try {
    const res: ReturnType<typeof purchasesRequestApi.getListPurchasesRequest> = yield call(purchasesRequestApi.getListPurchasesRequest, queryString)
    yield put(purchasesRequestActions.getListPurchasesRequestSuccess(res))
  } catch (error) {
    yield put(purchasesRequestActions.getListPurchasesRequestFail(error))
  }
}

function* fetchDetailPurchasesRequest({ payload: id }: FetchPurchasesRequestDetailSaga) {
  try {
    const res: ReturnType<typeof purchasesRequestApi.getDetailPurchasesRequest> = yield call(purchasesRequestApi.getDetailPurchasesRequest, id)
    yield put(purchasesRequestActions.getDetailPurchasesRequestSuccess(res))
  } catch (error) {
    yield put(purchasesRequestActions.getDetailPurchasesRequestFail(error))
  }
}

export default function* watchPurchasesRequest() {
  yield takeEvery(purchasesRequestActions.getListPurchasesRequest.toString(), fetchListPurchasesRequest)
  yield takeEvery(purchasesRequestActions.getDetailPurchasesRequest.toString(), fetchDetailPurchasesRequest)
}
