import Cookies from 'universal-cookie'
// import { AppDispatch } from '../redux/store'
// import * as userDucks from '../redux/ducks/userDucks'

const cookies = new Cookies()

export function logOut() {
  cookies.remove('access_token')
  cookies.remove('user_info')
  // dispatch(userDucks.setUserInfo(null))
  window.location.href = '/login'
}

export function getAccessToken() {
  return cookies.get('access_token')
}

export function getUserRole() {
  return cookies.get('user_role')
}

export function getProjectInfo() {
  return cookies.get('project_info')
}

export function getUserInfo() {
  return cookies.get('user_info')
}


export function saveAccessToken(value: string) {
  return cookies.set('access_token', value, {
    path: '/',
  })
}

export function saveUserRole(value: string) {
  return cookies.set('user_role', value, {
    path: '/',
  })
}

export function saveProjectInfo(value: string) {
  return cookies.set('project_info', value, {
    path: '/',
  })
}

export function saveUserInfo(value: string) {
  return cookies.set('user_info', value, {
    path: '/',
  })
}

export function isLoggedIn() {
  return !!getAccessToken()
}
