import { call, put, takeEvery } from 'redux-saga/effects'
import * as orderActions from '../ducks/orderDucks'
import * as orderApi from '../../api/orderApi'

interface FetchOrderListSaga {
  type: string
  payload: string
}

interface FetchOrderDetailSaga {
  type: string
  payload: string
}
interface FetchRequestListSaga {
  type: string
  payload: string
}
interface FetchBillOfLadingCodeListSaga {
  type: string
  payload: string
}

interface FetchReportListSaga {
  type: string
  payload: string
}

interface FetchSaga {
  type: string
  payload: string
}



function* fetchListOrder({ payload: queryString }: FetchOrderListSaga) {
  try {
    const res: ReturnType<typeof orderApi.getListOrder> = yield call(orderApi.getListOrder, queryString)
    yield put(orderActions.getListOrderSuccess(res))
  } catch (error) {
    yield put(orderActions.getListOrderFail(error))
  }
}

function* fetchDetailOrder({ payload: id }: FetchOrderDetailSaga) {
  try {
    const res: ReturnType<typeof orderApi.getDetailOrder> = yield call(orderApi.getDetailOrder, id)
    yield put(orderActions.getDetailOrderSuccess(res))
  } catch (error) {
    yield put(orderActions.getDetailOrderFail(error))
  }
}

function* fetchListRequest({ payload: queryString }: FetchRequestListSaga) {
  try {
    const res: ReturnType<typeof orderApi.getListRequest> = yield call(orderApi.getListRequest, queryString)
    yield put(orderActions.getListRequestSuccess(res))
  } catch (error) {
    yield put(orderActions.getListRequestFail(error))
  }
}

// báo cáo
function* fetchListReportCapitalPrice({ payload: queryString }: FetchReportListSaga) {
  try {
    const res: ReturnType<typeof orderApi.getListReportCapitalPrice> = yield call(orderApi.getListReportCapitalPrice, queryString)
    yield put(orderActions.getListReportCapitalPriceSuccess(res))
  } catch (error) {
    yield put(orderActions.getListReportCapitalPriceFail(error))
  }
}

function* fetchListReportDeptList({ payload: queryString }: FetchReportListSaga) {
  try {
    const res: ReturnType<typeof orderApi.getListReportDeptList> = yield call(orderApi.getListReportDeptList, queryString)
    yield put(orderActions.getListReportDeptListSuccess(res))
  } catch (error) {
    yield put(orderActions.getListReportDeptListFail(error))
  }
}

function* fetchListReportDeptDetail({ payload: queryString }: FetchReportListSaga) {
  try {
    const res: ReturnType<typeof orderApi.getListReportDeptDetail> = yield call(orderApi.getListReportDeptDetail, queryString)
    yield put(orderActions.getListReportDeptDetailSuccess(res))
  } catch (error) {
    yield put(orderActions.getListReportDeptDetailFail(error))
  }
}


function* fetchListReportOrderStatus({ payload: queryString }: FetchReportListSaga) {
  try {
    const res: ReturnType<typeof orderApi.getListReportOrderStatus> = yield call(orderApi.getListReportOrderStatus, queryString)
    yield put(orderActions.getListReportOrderStatusSuccess(res))
  } catch (error) {
    yield put(orderActions.getListReportOrderStatusFail(error))
  }
}



function* fetchListHistoryOrder({ payload: queryString }: FetchSaga) {
  try {
    const res: ReturnType<typeof orderApi.getListHistoryOrder> = yield call(orderApi.getListHistoryOrder, queryString)
    yield put(orderActions.getListHistoryOrderSuccess(res))
  } catch (error) {
    yield put(orderActions.getListHistoryOrderFail(error))
  }
}



function* fetchListBillOfLadingCode({ payload: queryString }: FetchBillOfLadingCodeListSaga) {
  try {
    const res: ReturnType<typeof orderApi.getListBillOfLadingCode> = yield call(orderApi.getListBillOfLadingCode, queryString)
    yield put(orderActions.getListBillOfLadingCodeSuccess(res))
  } catch (error) {
    yield put(orderActions.getListBillOfLadingCodeFail(error))
  }
}


export default function* watchOrder() {
  yield takeEvery(orderActions.getListOrder.toString(), fetchListOrder)
  yield takeEvery(orderActions.getDetailOrder.toString(), fetchDetailOrder)
  yield takeEvery(orderActions.getListRequest.toString(), fetchListRequest)
  // báo cáo
  yield takeEvery(orderActions.getListReportCapitalPrice.toString(), fetchListReportCapitalPrice)
  yield takeEvery(orderActions.getListReportDeptList.toString(), fetchListReportDeptList)
  yield takeEvery(orderActions.getListReportDeptDetail.toString(), fetchListReportDeptDetail)
  yield takeEvery(orderActions.getListReportOrderStatus.toString(), fetchListReportOrderStatus)

  yield takeEvery(orderActions.getListHistoryOrder.toString(), fetchListHistoryOrder)

  yield takeEvery(orderActions.getListBillOfLadingCode.toString(), fetchListBillOfLadingCode)



}
