import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface WarehouseState {
  warehouseList?: any
  warehouseDetail?: any
  sourceWarehouseList?: any
  desWarehouseList?: any

}

const initialState: WarehouseState = {
  warehouseList: {},
  warehouseDetail: {},
  sourceWarehouseList: {},
  desWarehouseList: {},

}

export const warehouseDucks = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
   
    getListWarehouse: (state: RootState, action: PayloadAction<any>) => {
      state.warehouseList = { loading: true, queryStr: action.payload }
    },
    getListWarehouseSuccess: (state, action: PayloadAction<any>) => {
      state.warehouseList = { loading: false, ...action.payload }
    },
    getListWarehouseFail: (state, action: PayloadAction<any>) => {
      state.warehouseList = { loading: false, ...action.payload }
    },
    
    getDetailWarehouse: (state: RootState, action: PayloadAction<any>) => {
      state.warehouseDetail = { loading: true, id: action.payload }
    },
    getDetailWarehouseSuccess: (state, action: PayloadAction<any>) => {
      state.warehouseDetail = { loading: false, ...action.payload }
    },
    getDetailWarehouseFail: (state, action: PayloadAction<any>) => {
      state.warehouseDetail = { loading: false, ...action.payload }
    },


    getListSourceWarehouse: (state: RootState, action: PayloadAction<any>) => {
      state.sourceWarehouseList = { loading: true, queryStr: action.payload }
    },
    getListSourceWarehouseSuccess: (state, action: PayloadAction<any>) => {
      state.sourceWarehouseList = { loading: false, ...action.payload }
    },
    getListSourceWarehouseFail: (state, action: PayloadAction<any>) => {
      state.sourceWarehouseList = { loading: false, ...action.payload }
    },
    
    getListDesWarehouse: (state: RootState, action: PayloadAction<any>) => {
      state.desWarehouseList = { loading: true, queryStr: action.payload }
    },
    getListDesWarehouseSuccess: (state, action: PayloadAction<any>) => {
      state.desWarehouseList = { loading: false, ...action.payload }
    },
    getListDesWarehouseFail: (state, action: PayloadAction<any>) => {
      state.desWarehouseList = { loading: false, ...action.payload }
    },
    
    
  },
})

// Action creators are generated for each case reducer function
export const {
  getListWarehouse,
  getListWarehouseSuccess,
  getListWarehouseFail,
  getDetailWarehouse,
  getDetailWarehouseSuccess,
  getDetailWarehouseFail,

  getListSourceWarehouse,
  getListSourceWarehouseSuccess,
  getListSourceWarehouseFail,

  getListDesWarehouse,
  getListDesWarehouseSuccess,
  getListDesWarehouseFail,

} = warehouseDucks.actions

export default warehouseDucks.reducer
