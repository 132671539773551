import { call, put, takeEvery } from 'redux-saga/effects'
import * as productGroupActions from '../ducks/productGroupDucks'
import * as productGroupApi from '../../api/productGroupApi'

interface FetchProductGroupListSaga {
  type: string
  payload: string
}

interface FetchProductGroupDetailSaga {
  type: string
  payload: string
}

function* fetchListProductGroup({ payload: queryString }: FetchProductGroupListSaga) {
  try {
    const res: ReturnType<typeof productGroupApi.getListProductGroup> = yield call(productGroupApi.getListProductGroup, queryString)
    yield put(productGroupActions.getListProductGroupSuccess(res))
  } catch (error) {
    yield put(productGroupActions.getListProductGroupFail(error))
  }
}

function* fetchDetailProductGroup({ payload: id }: FetchProductGroupDetailSaga) {
  try {
    const res: ReturnType<typeof productGroupApi.getDetailProductGroup> = yield call(productGroupApi.getDetailProductGroup, id)
    yield put(productGroupActions.getDetailProductGroupSuccess(res))
  } catch (error) {
    yield put(productGroupActions.getDetailProductGroupFail(error))
  }
}


export default function* watchProductGroup() {
  yield takeEvery(productGroupActions.getListProductGroup.toString(), fetchListProductGroup)
  yield takeEvery(productGroupActions.getDetailProductGroup.toString(), fetchDetailProductGroup)
}
